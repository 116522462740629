// Controle.js
import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { message } from 'antd';
import axios from '../../axios/axiosInstance';
import { useNavigate, useParams } from 'react-router-dom';
import Back from '../../images/intervention/back.png';
import Haut from '../../images/intervention/haut.jpg';
import Avant from '../../images/intervention/avant.jpg';
import Arriere from '../../images/intervention/arriere.jpg';
import Gauche from '../../images/intervention/gauche.jpg';
import Droite from '../../images/intervention/droite.jpg';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';
import Loader from '../../loaders/TireLoader';

function Restauration(props) {
    const [isLoading, setIsLoading] = useState(true); // Ajout de l'état isLoading
    const { id_intervention } = useParams();
    const axiosInstance = axios();
    const [vidangeData, setVidangeDate] = useState({});

    useEffect(() => {
        const fetchData = async () => {
            // Envoie des données par Axios à votre backend
            axiosInstance.get(`https://api.mytic-ci.com/Vidanges/${id_intervention}`)
                .then((response) => {
                    const data = response.data;
                    setVidangeDate(data[0]);
                    setIsLoading(false); // Mettre isLoading à false une fois les données chargées
                })
                .catch((error) => {
                    setIsLoading(false); // Mettre isLoading à false en cas d'erreur
                });
        };

        fetchData();
    }, []);

    return (
        <div>
            {isLoading ? ( // Afficher le Loader si isLoading est true
                <div className='info-vehicle-loader'>
                    <Loader />
                </div>
            ) : (
                <div>
                    <div>
                        <h3 className='type-intervention mx-4' style={{ color: '#015677' }}>Type d'intervention : <span style={{ color: 'black' }}>Vidange</span></h3>
                    </div>
                    <div className='mt-4'>
                        <div className='d-flex'>
                            <div className='mx-4 mb-4'>
                                <label htmlFor="immatriculation">Immatriculation</label>
                                <div>
                                    <input
                                        id="immatriculation"
                                        className="info-vehicle-input to-not-index"
                                        name="immatriculation"
                                        value={vidangeData.immatriculation}

                                    />
                                </div>
                            </div>
                            <div className='info-vehicle-input-container'>
                                <label htmlFor="date_vidange">Date de la vidange</label>
                                <input
                                    id="date_vidange"
                                    className="info-vehicle-input"
                                    name="date_vidange"
                                    value={vidangeData.date_vidange}
                                />
                            </div>
                        </div>
                        <div className='vidange-inputs-container'>
                            <div className='info-vehicle-input-container my-2'>
                                <label htmlFor="prix">prix</label>
                                <input
                                    id="prix"
                                    className="info-vehicle-input"
                                    name="prix"
                                    value={vidangeData.prix}
                                />
                            </div>
                            <div className='info-vehicle-input-container my-2 mx-5'>
                                <label htmlFor="kilometrage_limite">Kilométrage limite</label>
                                <div className="input-with-unit">
                                    <input
                                        id="kilometrage_limite"
                                        className="info-vehicle-input"
                                        name="kilometrage_limite"
                                        value={vidangeData.kilometrage_limite}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default Restauration;