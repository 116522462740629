import { React, useEffect } from "react";
import { BrowserRouter as Router, Switch, Route, Link, BrowserRouter, Routes } from "react-router-dom";
import Vehicules from './vehicules/Vehicules';
import InfoVehicule from './vehicules/InfoVehicule';
import AjoutVehicule from './vehicules/AjoutVehicule';
import Details from './details/Details';
import Login from './connexion/login';
import Interventions from './interventions/Interventions';
import AjoutIntervention from './interventions/AjoutIntervention';
import InfoIntervention from './interventions/InfoIntervention';
import Utilisateur from './utilisateurs/Utilisateur';

function App() {
  useEffect(() => {
    document.title = 'My-tic';

    return () => {
      document.title = 'My-tic'; // Remettre le titre par défaut si nécessaire
    };
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Login/>}/>
        <Route path='/Vehicules' element={<Vehicules/>}/>
        <Route path='/InfoVehicule/:id_vehicule' element={<InfoVehicule/>}/>
        <Route path='/AjoutVehicule' element={<AjoutVehicule/>}/>
        <Route path='/Details' element={<Details/>}/>
        <Route path='/Interventions' element={<Interventions/>}/>
        <Route path='/AjoutIntervention' element={<AjoutIntervention/>}/>
        <Route path='/InfoIntervention/:id_intervention' element={<InfoIntervention/>}/>
        <Route path='/Utilisateurs' element={<Utilisateur/>}/>
      </Routes>
    </BrowserRouter>
  );
}

export default App;