import { useState, React } from 'react';
import Header from './HeaderInterventions';
import Controle from './AjouterIntervention/Controle';
import Vidange from './AjouterIntervention/Vidange';
import Restauration from './AjouterIntervention/Restauration';
import Select from 'react-select';
import 'bootstrap/dist/css/bootstrap.min.css';
import './AjoutIntervention.css'


function AjoutIntervention(props) {
    const [selectedType, setSelectedType] = useState('controle-type');

    const handleTypeClick = (className) => {
        setSelectedType(className);
    };
    return (
        <div>
            <Header />
            <body>
                <div className='bas right-side mt-3'>
                    <div className='mx-4'>
                        <div>
                            <h3 className='type-intervention'>Type d'intervention</h3>
                        </div>
                        <div className="intervention-toggle-state mt-4">
                            <div
                                className={`controle-type type mx-1 d-flex align-items-center justify-content-center ${selectedType === 'controle-type' ? 'selected-type' : 'other-type'}`}
                                onClick={() => handleTypeClick('controle-type')}
                            >
                                Contrôle
                            </div>
                            <div
                                className={`vidange-type type mx-1 d-flex align-items-center justify-content-center ${selectedType === 'vidange-type' ? 'selected-type' : 'other-type'}`}
                                onClick={() => handleTypeClick('vidange-type')}
                            >
                                Vidange
                            </div>
                            <div
                                className={`restauration-type mx-1 type d-flex align-items-center justify-content-center ${selectedType === 'restauration-type' ? 'selected-type' : 'other-type'}`}
                                onClick={() => handleTypeClick('restauration-type')}
                            >
                                Restauration
                            </div>
                        </div>
                    </div>
                    <div>
                        {selectedType === 'controle-type' &&
                            <div>
                                <Controle/>
                            </div>
                        }
                        
                        {selectedType === 'vidange-type' &&
                            <div>
                                <Vidange/>
                            </div>
                        }
                        
                        {selectedType === 'restauration-type' &&
                            <div>
                                <Restauration/>
                            </div>
                        }

                    </div>
                </div>
            </body>
        </div>
    );
}

export default AjoutIntervention;