import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Controle.css';
import { message } from 'antd';
import axios from '../../axios/axiosInstance';
import Nav from 'react-bootstrap/Nav';
import Back from '../../images/intervention/back.png';
import Haut from '../../images/intervention/haut.jpg';
import Avant from '../../images/intervention/avant.jpg';
import Arriere from '../../images/intervention/arriere.jpg';
import Gauche from '../../images/intervention/gauche.jpg';
import Droite from '../../images/intervention/droite.jpg';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';
import { useNavigate, useParams } from 'react-router-dom';


function Controle(props) {
    const navigate = useNavigate();
    const axiosInstance = axios();
    const [isChecked, setIsChecked] = useState(false);
    const [circleCoordinates, setCircleCoordinates] = useState([]);
    const [checkboxStates, setCheckboxStates] = useState({
        accident: false,
        proprete: false,
        essuie_glace: false,
        pneus: false,
        feux_arrieres: false,
        tapis: false,
        enjoliveurs: false,
        phares: false,
        antenne: false,
        kit_de_securite: false,
        cric: false,
        manivelle: false,
        roue_de_secours: false,
        boite_de_vitesse: false,
        passage_de_roue: false,
        plaque_immatriculation: false,
        retroviseur: false,
    });

    const [kilometrageActuel, setKilometrageActuel] = useState('');
    const [immatriculationOptions, setImmatriculationOptions] = useState([]);

    useEffect(() => {
        // Récupérer la liste des immatriculations depuis l'API 'https://api.mytic-ci.com/Vehicules'
        const fetchImmatriculations = async () => {
            try {
                const response = await axiosInstance.get('https://api.mytic-ci.com/Vehicules');
                const immatriculations = response.data.map(vehicule => ({ value: vehicule.immatriculation, label: vehicule.immatriculation }));
                setImmatriculationOptions(immatriculations);
            } catch (error) {
                console.error('Erreur lors de la récupération des immatriculations:', error);
            }
        };

        fetchImmatriculations();
    }, [axiosInstance]);

    const handleKilometrageChange = (event) => {
        setKilometrageActuel(event.target.value);
    };

    const handleCheckboxChange = (event) => {
        const { name, checked } = event.target;

        if (name === 'accident') {
            setIsChecked(checked);
        }

        // Update the checkboxStates with the new checked value
        setCheckboxStates((prevCheckboxStates) => ({
            ...prevCheckboxStates,
            [name]: checked,
        }));
    };

    const handleCircleClick = (index) => {
        const updatedShapes = shapes.filter((_, i) => i !== index);
        setShapes(updatedShapes);
    };


    const [shapes, setShapes] = useState([]);

    const handleCanvasClick = (event) => {
        const boundingRect = event.currentTarget.getBoundingClientRect(); // Obtenir les coordonnées du conteneur
        const offsetX = event.clientX - boundingRect.left; // Coordonnée x relative à la zone de dessin
        const offsetY = event.clientY - boundingRect.top; // Coordonnée y relative à la zone de dessin

        const newShape = {
            x: offsetX - 23,
            y: offsetY - 23,
            type: 'circle',
        };

        setShapes([...shapes, newShape]);
    };

    const handleFormSubmit = async (event) => {
        event.preventDefault();

        // Vérifier si le champ kilometrage_actuel est vide
        if (kilometrageActuel.trim() === '') {
            alert('Le champ Kilométrage actuel est obligatoire.');
            return;
        }

        // Récupérer les valeurs d'immatriculation et kilométrage_actuel
        const immatriculationValue = document.getElementById('immatriculation').textContent;
        const kilometrageActuelValue = kilometrageActuel.trim()

        // Accidents est vrai, vous pouvez envoyer les coordonnées des cercles
        const dataToSend = {
            immatriculation: immatriculationValue,
            ...checkboxStates,
            kilometrage_actuel: kilometrageActuel,
            circleCoordinates: shapes,
        };

        console.log(dataToSend);

        // Vérifier si les champs obligatoires sont remplis
        if (!immatriculationValue || !kilometrageActuelValue) {
            message.error('Remplissez tous les champs.');
            return;
        } else {
            // Envoie des données par Axios à votre backend
            try {
                console.log(dataToSend);
                const response = await axiosInstance.post('https://api.mytic-ci.com/Controles/AjouterControle', dataToSend);
            } catch (error) {
                message.success('Contrôle ajouté avec succès');
                navigate('/Interventions');
            };
        };
    }

    const handleFormSubmitWithoutAccident = async (event) => {
        event.preventDefault();

        // Récupérer les valeurs d'immatriculation et kilométrage_actuel
        const immatriculationValue = document.getElementById('immatriculation').textContent;
        const kilometrageActuelValue = kilometrageActuel.trim();

        // Vérifier si les champs obligatoires sont remplis
        if (!immatriculationValue || !kilometrageActuelValue) {
            message.error('Remplissez tous les champs.');
            return;
        } else {
            // Accidents est faux, pas besoin des coordonnées des cercles
            const dataToSend = {
                immatriculation: immatriculationValue,
                ...checkboxStates,
                kilometrage_actuel: kilometrageActuelValue,
            };

            // Envoie des données par Axios à votre backend
            try {
                console.log(dataToSend);
                const response = await axiosInstance.post('https://api.mytic-ci.com/Controles/AjouterControle', dataToSend);
                await message.success('Contrôle ajouté avec succès');
                navigate('/Interventions');
            } catch (error) {
                await message.success('Contrôle ajouté avec succès');
                navigate('/Interventions');
            }
        }
    };


    return (
        <div>
            <form>
                <div>
                    <div className='mt-3 mx-4'>
                        <div className='d-flex'>
                            <div className='mb-4'>
                                <label htmlFor="immatriculation">Immatriculation</label>
                                <div>
                                    <Select
                                        id="immatriculation"
                                        className="info-vehicle-input to-not-index"
                                        name="immatriculation"
                                        options={immatriculationOptions}
                                        onChange={(selectedOption) => console.log(selectedOption)} // Gérer la sélection de l'option
                                        styles={{

                                            input: (baseStyles, state) => ({
                                                ...baseStyles,


                                            }),

                                            control: (baseStyles, state) => ({
                                                ...baseStyles,
                                                border: 'none',
                                                borderRadius: '4px',
                                                backgroundColor: '#EDEDED',
                                                color: '#333',
                                                height: '43px',
                                                width: '200px',
                                                outline: 'none',
                                                display: 'flex',
                                                flexDirection: 'row',
                                                ':focus': {
                                                    marginLeft: '20px'
                                                }
                                            }),
                                            dropdownIndicator: () => ({
                                                marginLeft: '10px',
                                                marginRight: '10px',
                                                ':focus': {
                                                    border: '1px solid'
                                                }
                                            }),
                                        }}
                                    />
                                </div>
                            </div>
                            <div className='info-vehicle-input-container mb-4'>
                                <label htmlFor="kilometrage_actuel">Kilométrage actuel</label>
                                <div>
                                    <input
                                        id="kilometrage_actuel"
                                        className="info-vehicle-input to-not-index"
                                        name="kilometrage_actuel"
                                        type='number'
                                        onChange={handleKilometrageChange} // Ajoutez cet événement onChange
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='checkboxes'>
                            <div className='to-check-left'>
                                <div class="checkbox-wrapper-4">
                                    <input class="inp-cbx" id="proprete" type="checkbox" name='proprete' onChange={handleCheckboxChange} />
                                    <label class="cbx" for="proprete"><span>
                                        <svg width="12px" height="10px">
                                        </svg></span><span>Propreté</span></label>
                                    <svg class="inline-svg">
                                        <symbol id="check-4" viewBox="0 0 12 10">
                                            <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                                        </symbol>
                                    </svg>
                                </div>
                                <div class="checkbox-wrapper-4">
                                    <input class="inp-cbx" id="essuie-glace" type="checkbox" name='essuie_glace' onChange={handleCheckboxChange} />
                                    <label class="cbx" for="essuie-glace"><span>
                                        <svg width="12px" height="10px">
                                        </svg></span><span>Essuie-glace</span></label>
                                    <svg class="inline-svg">
                                        <symbol id="check-4" viewBox="0 0 12 10">
                                            <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                                        </symbol>
                                    </svg>
                                </div>
                                <div class="checkbox-wrapper-4">
                                    <input class="inp-cbx" id="pneus" type="checkbox" name='pneus' onChange={handleCheckboxChange} />
                                    <label class="cbx" for="pneus"><span>
                                        <svg width="12px" height="10px">
                                        </svg></span><span>Pneus</span></label>
                                    <svg class="inline-svg">
                                        <symbol id="check-4" viewBox="0 0 12 10">
                                            <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                                        </symbol>
                                    </svg>
                                </div>
                                <div class="checkbox-wrapper-4">
                                    <input class="inp-cbx" id="feux-arrieres" type="checkbox" name='feux_arrieres' onChange={handleCheckboxChange} />
                                    <label class="cbx" for="feux-arrieres"><span>
                                        <svg width="12px" height="10px">
                                        </svg></span><span>Feux arrières</span></label>
                                    <svg class="inline-svg">
                                        <symbol id="check-4" viewBox="0 0 12 10">
                                            <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                                        </symbol>
                                    </svg>
                                </div>
                                <div class="checkbox-wrapper-4">
                                    <input class="inp-cbx" id="tapis" type="checkbox" name='tapis' onChange={handleCheckboxChange} />
                                    <label class="cbx" for="tapis"><span>
                                        <svg width="12px" height="10px">
                                        </svg></span><span>Tapis</span></label>
                                    <svg class="inline-svg">
                                        <symbol id="check-4" viewBox="0 0 12 10">
                                            <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                                        </symbol>
                                    </svg>
                                </div>
                                <div class="checkbox-wrapper-4">
                                    <input class="inp-cbx" id="enjoliveurs" type="checkbox" name='enjoliveurs' onChange={handleCheckboxChange} />
                                    <label class="cbx" for="enjoliveurs"><span>
                                        <svg width="12px" height="10px">
                                        </svg></span><span>Enjoliveurs</span></label>
                                    <svg class="inline-svg">
                                        <symbol id="check-4" viewBox="0 0 12 10">
                                            <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                                        </symbol>
                                    </svg>
                                </div>
                                <div class="checkbox-wrapper-4">
                                    <input class="inp-cbx" id="phares" type="checkbox" name='phares' onChange={handleCheckboxChange} />
                                    <label class="cbx" for="phares"><span>
                                        <svg width="12px" height="10px">
                                        </svg></span><span>Phares</span></label>
                                    <svg class="inline-svg">
                                        <symbol id="check-4" viewBox="0 0 12 10">
                                            <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                                        </symbol>
                                    </svg>
                                </div>
                                <div class="checkbox-wrapper-4">
                                    <input class="inp-cbx" id="plaque_immatriculation" type="checkbox" name='plaque_immatriculation' onChange={handleCheckboxChange} />
                                    <label class="cbx" for="plaque_immatriculation"><span>
                                        <svg width="12px" height="10px">
                                        </svg></span><span>plaque d'immatriculation</span></label>
                                    <svg class="inline-svg">
                                        <symbol id="check-4" viewBox="0 0 12 10">
                                            <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                                        </symbol>
                                    </svg>
                                </div>
                            </div>
                            <div className='to-check-right mx-5'>
                                <div class="checkbox-wrapper-4">
                                    <input class="inp-cbx" id="antenne" type="checkbox" name='antenne' onChange={handleCheckboxChange} />
                                    <label class="cbx" for="antenne"><span>
                                        <svg width="12px" height="10px">
                                        </svg></span><span>Antenne</span></label>
                                    <svg class="inline-svg">
                                        <symbol id="check-4" viewBox="0 0 12 10">
                                            <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                                        </symbol>
                                    </svg>
                                </div>
                                <div class="checkbox-wrapper-4">
                                    <input class="inp-cbx" id="kit-de-securite" type="checkbox" name='kit_de_securite' onChange={handleCheckboxChange} />
                                    <label class="cbx" for="kit-de-securite"><span>
                                        <svg width="12px" height="10px">
                                        </svg></span><span>Kit de sécurité</span></label>
                                    <svg class="inline-svg">
                                        <symbol id="check-4" viewBox="0 0 12 10">
                                            <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                                        </symbol>
                                    </svg>
                                </div>
                                <div class="checkbox-wrapper-4">
                                    <input class="inp-cbx" id="cric" type="checkbox" name='cric' onChange={handleCheckboxChange} />
                                    <label class="cbx" for="cric"><span>
                                        <svg width="12px" height="10px">
                                        </svg></span><span>Cric</span></label>
                                    <svg class="inline-svg">
                                        <symbol id="check-4" viewBox="0 0 12 10">
                                            <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                                        </symbol>
                                    </svg>
                                </div>
                                <div class="checkbox-wrapper-4">
                                    <input class="inp-cbx" id="manivelle" type="checkbox" name='manivelle' onChange={handleCheckboxChange} />
                                    <label class="cbx" for="manivelle"><span>
                                        <svg width="12px" height="10px">
                                        </svg></span><span>Manivelle</span></label>
                                    <svg class="inline-svg">
                                        <symbol id="check-4" viewBox="0 0 12 10">
                                            <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                                        </symbol>
                                    </svg>
                                </div>
                                <div class="checkbox-wrapper-4">
                                    <input class="inp-cbx" id="roue-de-secours" type="checkbox" name='roue_de_secours' onChange={handleCheckboxChange} />
                                    <label class="cbx" for="roue-de-secours"><span>
                                        <svg width="12px" height="10px">
                                        </svg></span><span>Roue de secours</span></label>
                                    <svg class="inline-svg">
                                        <symbol id="check-4" viewBox="0 0 12 10">
                                            <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                                        </symbol>
                                    </svg>
                                </div>
                                <div class="checkbox-wrapper-4">
                                    <input class="inp-cbx" id="boite-de*vitesse" type="checkbox" name='boite_de_vitesse' onChange={handleCheckboxChange} />
                                    <label class="cbx" for="boite-de*vitesse"><span>
                                        <svg width="12px" height="10px">
                                        </svg></span><span>Boite de vitesse</span></label>
                                    <svg class="inline-svg">
                                        <symbol id="check-4" viewBox="0 0 12 10">
                                            <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                                        </symbol>
                                    </svg>
                                </div>
                                <div class="checkbox-wrapper-4">
                                    <input class="inp-cbx" id="passage-de-roue" type="checkbox" name='passage_de_roue' onChange={handleCheckboxChange} />
                                    <label class="cbx" for="passage-de-roue"><span>
                                        <svg width="12px" height="10px">
                                        </svg></span><span>Passage de roue</span></label>
                                    <svg class="inline-svg">
                                        <symbol id="check-4" viewBox="0 0 12 10">
                                            <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                                        </symbol>
                                    </svg>
                                </div>
                                <div class="checkbox-wrapper-4">
                                    <input class="inp-cbx" id="retroviseur" type="checkbox" name='retroviseur' onChange={handleCheckboxChange} />
                                    <label class="cbx" for="retroviseur"><span>
                                        <svg width="12px" height="10px">
                                        </svg></span><span>Rétroviseur</span></label>
                                    <svg class="inline-svg">
                                        <symbol id="check-4" viewBox="0 0 12 10">
                                            <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                                        </symbol>
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='mx-4'>
                        <div className='d-flex mb-3 mx-3' style={{ fontWeight: '600', marginLeft: '0px', marginTop: '35px' }}>
                            Accident
                            <input id="checkbox" name="accident" type="checkbox" onClick={handleCheckboxChange} />
                            <label class="label" for="checkbox" style={{ marginLeft: '13px', marginTop: '-10px' }} />
                        </div>
                        <div>
                            {
                                //En cas d'accident
                                isChecked ?
                                    <div>
                                        <div className='card' style={{ width: '750px', marginLeft: '0px' }}
                                            onClick={handleCanvasClick}>
                                            <Row style={{ marginTop: '20px', }}>
                                                <Col style={{ marginLeft: '250px' }}>
                                                    <Image src={Haut} height="110px" rounded />
                                                </Col>
                                            </Row>
                                            <Row style={{ marginTop: '50px', marginLeft: '20px' }}>
                                                <Col>
                                                    <Image src={Gauche} height="110px" rounded />
                                                </Col>
                                                <Col>
                                                    <Image src={Droite} height="110px" rounded />
                                                </Col>
                                            </Row>
                                            <Row style={{ marginTop: '50px', marginLeft: '180px', marginBottom: '20px' }}>
                                                <Col>
                                                    <Image src={Arriere} height="110px" rounded />
                                                </Col>
                                                <Col>
                                                    <Image src={Avant} height="110px" rounded />
                                                </Col>
                                            </Row>
                                            {shapes.map((shape, index) => (
                                                <div
                                                    key={index}
                                                    onClick={() => handleCircleClick(index)}
                                                    style={{
                                                        position: 'absolute',
                                                        left: shape.x,
                                                        top: shape.y,
                                                        width: '50px',
                                                        height: '50px',
                                                        borderRadius: '50%',
                                                        border: '2px solid red',
                                                        cursor: 'pointer', // Changer le curseur au survol
                                                    }}
                                                ></div>
                                            ))}
                                        </div>
                                        <div>
                                            <button className='btn-enregistrer mb-2' onClick={handleFormSubmit} style={{ marginTop: '7%' }}>Enregistrer</button>
                                        </div>
                                    </div>
                                    :
                                    <div>
                                        <button className='btn-enregistrer mb-2' onClick={handleFormSubmitWithoutAccident} style={{ marginTop: '7%' }}>Enregistrer</button>
                                    </div>
                            }
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
}

export default Controle;