import { useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const AxiosInstanceProvider = () => {
  const myticToken = localStorage.getItem('mytic_token');
  const navigate = useNavigate();

  const axiosInstance = axios.create({
    headers: {
      'Authorization': myticToken,
    },
  });

  useEffect(() => {
    const requestInterceptor = axiosInstance.interceptors.request.use(
      (config) => config,
      (error) => Promise.reject(error)
    );

    const responseInterceptor = axiosInstance.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response.status === 401) {
          navigate('/');
        }
        return Promise.reject(error);
      }
    );

    // Cleanup interceptors when the component is unmounted
    return () => {
      axiosInstance.interceptors.request.eject(requestInterceptor);
      axiosInstance.interceptors.response.eject(responseInterceptor);
    };
  }, [axiosInstance, navigate]);

  // You can return the axios instance if needed
  return axiosInstance;
};

export default AxiosInstanceProvider;
