import React, { useState, useEffect, useRef } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { message } from 'antd';
import axios from '../../axios/axiosInstance';
import { useNavigate, useParams } from 'react-router-dom';
import Back from '../../images/intervention/back.png';
import html2pdf from 'html2pdf.js';  // Import html2pdf
import Haut from '../../images/intervention/haut.jpg';
import Avant from '../../images/intervention/avant.jpg';
import Arriere from '../../images/intervention/arriere.jpg';
import Gauche from '../../images/intervention/gauche.jpg';
import Droite from '../../images/intervention/droite.jpg';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';
import Loader from '../../loaders/TireLoader';

function Controle(props) {
    const { id_intervention } = useParams();
    const axiosInstance = axios();
    const [controleData, setControleData] = useState({});
    const [isLoading, setIsLoading] = useState(true); // Ajout de l'état isLoading
    const componentRef = useRef();  // Create a ref for the component

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axiosInstance.get(`https://api.mytic-ci.com/Controles/${id_intervention}`);
                const data = response.data.controlData;
                const circles = data.circles;
                setControleData({ ...data, circles });
                setIsLoading(false); // Mettre isLoading à false une fois les données chargées
            } catch (error) {
                console.error('Error fetching controle data:', error);
                setIsLoading(false); // Mettre isLoading à false en cas d'erreur
            }
        };

        fetchData();
    }, []);

    const downloadPDF = () => {
        const input = componentRef.current;  // Get the reference to the component
        html2pdf(input);
    };

    return (
        <div>
            {isLoading ? ( // Afficher le Loader si isLoading est true
                <div className='info-vehicle-loader'>
                    <Loader />
                </div>
            ) : (
                <div>
                    <div>
                        {/* ... rest of your component code ... */}

                        {/* Add a button to trigger PDF download */}
                        <button onClick={downloadPDF}>Télécharger en PDF</button>
                    </div>
                    <div className='mt-3 mx-4' ref={componentRef}>
                        <div>
                            <div>
                                <h3 className='type-intervention' style={{ color: '#015677' }}>Type d'intervention : <span style={{ color: 'black' }}>Contrôle</span></h3>
                            </div>
                            <div className='d-flex mt-4'>
                                <div className='mb-4'>
                                    <label htmlFor="immatriculation">Immatriculation</label>
                                    <div>
                                        <input className="info-vehicle-input to-not-index" value={controleData.immatriculation} readOnly
                                        />
                                    </div>
                                </div>
                                <div className='info-vehicle-input-container mb-4'>
                                    <label htmlFor="kilometrage_actuel">Kilométrage actuel</label>
                                    <div>
                                        <input id="kilometrage_actuel" className="info-vehicle-input to-not-index" name="kilometrage_actuel" readOnly value={controleData.kilometrage_actuel}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='checkboxes'>
                                <div className='to-check-left'>
                                    <div className="checkbox-wrapper-4">
                                        <input className="inp-cbx" id="proprete" type="checkbox" name='proprete' readOnly checked={controleData.proprete === '1'}
                                        />
                                        <label className="cbx" htmlFor="proprete">
                                            <span></span><span>Propreté</span>
                                        </label>
                                        <svg className="inline-svg">
                                            <symbol id="check-4" viewBox="0 0 12 10">
                                                <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                                            </symbol>
                                        </svg>
                                    </div>
                                    <div className="checkbox-wrapper-4">
                                        <input className="inp-cbx" id="essuie-glace" type="checkbox" name='essuie_glace' readOnly checked={controleData.essuie_glace === '1'} />
                                        <label className="cbx" htmlFor="essuie-glace">
                                            <span></span><span>Essuie-glace</span>
                                        </label>
                                        <svg className="inline-svg">
                                            <symbol id="check-4" viewBox="0 0 12 10">
                                                <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                                            </symbol>
                                        </svg>
                                    </div>
                                    <div className="checkbox-wrapper-4">
                                        <input className="inp-cbx" id="pneus" type="checkbox" name='pneus' checked={controleData.pneus === '1'} readOnly />
                                        <label className="cbx" htmlFor="pneus">
                                            <span></span><span>Pneus</span>
                                        </label>
                                        <svg className="inline-svg">
                                            <symbol id="check-4" viewBox="0 0 12 10">
                                                <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                                            </symbol>
                                        </svg>
                                    </div>
                                    <div class="checkbox-wrapper-4">
                                        <input class="inp-cbx" id="feux-arrieres" type="checkbox" name='feux_arrieres' checked={controleData.feux_arrieres === '1'} readOnly />
                                        <label class="cbx" for="feux-arrieres"><span>
                                            <svg width="12px" height="10px">
                                            </svg></span><span>Feux arrières</span></label>
                                        <svg class="inline-svg">
                                            <symbol id="check-4" viewBox="0 0 12 10">
                                                <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                                            </symbol>
                                        </svg>
                                    </div>
                                    <div class="checkbox-wrapper-4">
                                        <input class="inp-cbx" id="tapis" type="checkbox" name='tapis' checked={controleData.tapis === '1'} readOnly />
                                        <label class="cbx" for="tapis"><span>
                                            <svg width="12px" height="10px">
                                            </svg></span><span>Tapis</span></label>
                                        <svg class="inline-svg">
                                            <symbol id="check-4" viewBox="0 0 12 10">
                                                <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                                            </symbol>
                                        </svg>
                                    </div>
                                    <div class="checkbox-wrapper-4">
                                        <input class="inp-cbx" id="enjoliveurs" type="checkbox" name='enjoliveurs' checked={controleData.enjoliveurs === '1'} readOnly />
                                        <label class="cbx" for="enjoliveurs"><span>
                                            <svg width="12px" height="10px">
                                            </svg></span><span>Enjoliveurs</span></label>
                                        <svg class="inline-svg">
                                            <symbol id="check-4" viewBox="0 0 12 10">
                                                <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                                            </symbol>
                                        </svg>
                                    </div>
                                    <div class="checkbox-wrapper-4">
                                        <input class="inp-cbx" id="phares" type="checkbox" name='phares' checked={controleData.phares === '1'} readOnly />
                                        <label class="cbx" for="phares"><span>
                                            <svg width="12px" height="10px">
                                            </svg></span><span>Phares</span></label>
                                        <svg class="inline-svg">
                                            <symbol id="check-4" viewBox="0 0 12 10">
                                                <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                                            </symbol>
                                        </svg>
                                    </div>
                                    <div class="checkbox-wrapper-4">
                                        <input class="inp-cbx" id="plaque_immatriculation" type="checkbox" name='plaque_immatriculation' checked={controleData.plaque_immatriculation === '1'} readOnly />
                                        <label class="cbx" for="plaque_immatriculation"><span>
                                            <svg width="12px" height="10px">
                                            </svg></span><span>plaque d'immatriculation</span></label>
                                        <svg class="inline-svg">
                                            <symbol id="check-4" viewBox="0 0 12 10">
                                                <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                                            </symbol>
                                        </svg>
                                    </div>
                                </div>
                                <div className='to-check-right mx-5'>
                                    <div class="checkbox-wrapper-4">
                                        <input class="inp-cbx" id="antenne" type="checkbox" name='antenne' checked={controleData.antenne === '1'} readOnly />
                                        <label class="cbx" for="antenne"><span>
                                            <svg width="12px" height="10px">
                                            </svg></span><span>Antenne</span></label>
                                        <svg class="inline-svg">
                                            <symbol id="check-4" viewBox="0 0 12 10">
                                                <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                                            </symbol>
                                        </svg>
                                    </div>
                                    <div class="checkbox-wrapper-4">
                                        <input class="inp-cbx" id="kit-de-securite" type="checkbox" name='kit_de_securite' checked={controleData.kit_de_securite === '1'} readOnly />
                                        <label class="cbx" for="kit-de-securite"><span>
                                            <svg width="12px" height="10px">
                                            </svg></span><span>Kit de sécurité</span></label>
                                        <svg class="inline-svg">
                                            <symbol id="check-4" viewBox="0 0 12 10">
                                                <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                                            </symbol>
                                        </svg>
                                    </div>
                                    <div class="checkbox-wrapper-4">
                                        <input class="inp-cbx" id="cric" type="checkbox" name='cric' checked={controleData.cric === '1'} readOnly />
                                        <label class="cbx" for="cric"><span>
                                            <svg width="12px" height="10px">
                                            </svg></span><span>Cric</span></label>
                                        <svg class="inline-svg">
                                            <symbol id="check-4" viewBox="0 0 12 10">
                                                <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                                            </symbol>
                                        </svg>
                                    </div>
                                    <div class="checkbox-wrapper-4">
                                        <input class="inp-cbx" id="manivelle" type="checkbox" name='manivelle' checked={controleData.manivelle === '1'} readOnly />
                                        <label class="cbx" for="manivelle"><span>
                                            <svg width="12px" height="10px">
                                            </svg></span><span>Manivelle</span></label>
                                        <svg class="inline-svg">
                                            <symbol id="check-4" viewBox="0 0 12 10">
                                                <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                                            </symbol>
                                        </svg>
                                    </div>
                                    <div class="checkbox-wrapper-4">
                                        <input class="inp-cbx" id="roue-de-secours" type="checkbox" name='roue_de_secours' checked={controleData.roue_de_secours === '1'} readOnly />
                                        <label class="cbx" for="roue-de-secours"><span>
                                            <svg width="12px" height="10px">
                                            </svg></span><span>Roue de secours</span></label>
                                        <svg class="inline-svg">
                                            <symbol id="check-4" viewBox="0 0 12 10">
                                                <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                                            </symbol>
                                        </svg>
                                    </div>
                                    <div class="checkbox-wrapper-4">
                                        <input class="inp-cbx" id="boite-de-vitesse" type="checkbox" name='boite_de_vitesse' checked={controleData.boite_de_vitesse === '1'} readOnly />
                                        <label class="cbx" for="boite-de-vitesse"><span>
                                            <svg width="12px" height="10px">
                                            </svg></span><span>Boite de vitesse</span></label>
                                        <svg class="inline-svg">
                                            <symbol id="check-4" viewBox="0 0 12 10">
                                                <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                                            </symbol>
                                        </svg>
                                    </div>
                                    <div class="checkbox-wrapper-4">
                                        <input class="inp-cbx" id="passage-de-roue" type="checkbox" name='passage_de_roue' checked={controleData.passage_de_roue === '1'} readOnly />
                                        <label class="cbx" for="passage-de-roue"><span>
                                            <svg width="12px" height="10px">
                                            </svg></span><span>Passage de roue</span></label>
                                        <svg class="inline-svg">
                                            <symbol id="check-4" viewBox="0 0 12 10">
                                                <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                                            </symbol>
                                        </svg>
                                    </div>
                                    <div class="checkbox-wrapper-4">
                                        <input class="inp-cbx" id="retroviseur" type="checkbox" name='retroviseur' checked={controleData.retroviseur === '1'} readOnly />
                                        <label class="cbx" for="retroviseur"><span>
                                            <svg width="12px" height="10px">
                                            </svg></span><span>Rétroviseur</span></label>
                                        <svg class="inline-svg">
                                            <symbol id="check-4" viewBox="0 0 12 10">
                                                <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                                            </symbol>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                            <div className='mx-4'>
                                <div className='d-flex mx-3' style={{ fontWeight: '600', marginLeft: '0px', marginTop: '35px' }}>
                                    Accident
                                    <input id="checkbox" name="accident" type="checkbox" readOnly checked={controleData.accident === '1'} />
                                    <label className="label" htmlFor="checkbox" style={{ marginLeft: '13px', marginTop: '-10px' }}>
                                        <svg width="12px" height="10px">
                                            <use href="#check-4"></use>
                                        </svg>
                                    </label>
                                    <svg className="inline-svg">
                                        <symbol id="check-4" viewBox="0 0 12 10">
                                            <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                                        </symbol>
                                    </svg>
                                </div>
                            </div>
                        </div>
                        <div style={{marginTop: '-95px', marginBottom: '10px'}}>
                            {
                                //En cas d'accident
                                controleData.accident === '0' ?
                                    <div>

                                    </div>
                                    :
                                    <div>
                                        <div className='card' style={{ width: '750px', marginLeft: '0px' }}>
                                            <Row style={{ marginTop: '20px', }}>
                                                <Col style={{ marginLeft: '250px' }}>
                                                    <Image src={Haut} height="110px" rounded />
                                                </Col>
                                            </Row>
                                            <Row style={{ marginTop: '50px', marginLeft: '20px' }}>
                                                <Col>
                                                    <Image src={Gauche} height="110px" rounded />
                                                </Col>
                                                <Col>
                                                    <Image src={Droite} height="110px" rounded />
                                                </Col>
                                            </Row>
                                            <Row style={{ marginTop: '50px', marginLeft: '180px', marginBottom: '20px' }}>
                                                <Col>
                                                    <Image src={Arriere} height="110px" rounded />
                                                </Col>
                                                <Col>
                                                    <Image src={Avant} height="110px" rounded />
                                                </Col>
                                            </Row>
                                            {controleData.circles &&
                                                JSON.parse(controleData.circles).map((circle, index) => (
                                                    <div
                                                        key={index}
                                                        style={{
                                                            position: 'absolute',
                                                            left: `${circle.x}px`,
                                                            top: `${circle.y}px`,
                                                            width: '50px',
                                                            height: '50px',
                                                            borderRadius: '50%',
                                                            border: '2px solid red',
                                                            cursor: 'pointer',
                                                        }}
                                                    ></div>
                                                ))}
                                        </div>
                                    </div>
                            }
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default Controle;
