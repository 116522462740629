import { useState, useEffect, React } from 'react';
import Header from './HeaderInterventions';
import Controle from './InfoIntervention/Controle';
import Vidange from './InfoIntervention/Vidange';
import Restauration from './InfoIntervention/Restauration';
import Select from 'react-select';
import 'bootstrap/dist/css/bootstrap.min.css';
import './AjoutIntervention.css'
import { useNavigate, useParams } from 'react-router-dom';
import axios from '../axios/axiosInstance';


function InfoIntervention(props) {
    const [controleData, setControleData] = useState({});
    const { id_intervention } = useParams();
    const axiosInstance = axios();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axiosInstance.get(`https://api.mytic-ci.com/Interventions/${id_intervention}`);
                const data = response.data;
                console.log(data)
                setControleData(data);
            } catch (error) {
                console.error('Error fetching controle data:', error);
            }
        };

        fetchData();
    }, []);

    return (
        <div>
            <Header />
            <body>
                <div className='bas right-side mt-3'>
                    <div>
                        {controleData.type_intervention === 'controle' &&
                            <div>
                                <Controle />
                            </div>
                        }

                        {controleData.type_intervention === 'vidange' &&
                            <div>
                                <Vidange />
                            </div>
                        }

                        {controleData.type_intervention === 'restauration' &&
                            <div>
                                <Restauration />
                            </div>
                        }

                    </div>
                </div>
            </body>
        </div>
    );
}

export default InfoIntervention;