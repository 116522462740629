import { React, useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { message, Modal } from 'antd';
import Header from './HeaderUtilisateurs';
import './Utilisateur.css';
import axios from '../axios/axiosInstance';
import TireLoader from "../loaders/TireLoader";

function Utilisateur() {
    const [datas, setDatas] = useState([]);
    const [profileImage, setProfileImage] = useState();
    const [selectedFile, setSelectedFile] = useState(null);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [loading, setLoading] = useState(true);
    const axiosInstance = axios()

    useEffect(() => {
        // Fetch data from the API
        axiosInstance.get('https://api.mytic-ci.com/Utilisateurs')
            .then(response => {
                setDatas(response.data[0]);
                console.log(datas);
                if (response.data[0].photo_profil.data.length !== 0) {
                    const imageDataArray = response.data[0].photo_profil.data;
                    const byteArray = new Uint8Array(imageDataArray);
                    const blob = new Blob([byteArray], { type: 'image/jpeg' }); // Assuming the image type is JPEG
                    console.log('Image URL:', blob);
                    setSelectedFile({ file: blob, name: datas.image_name });
                }
                setLoading(false)
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }, [axiosInstance]);




    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setSelectedFile({ file: file, name: file.name });
        file && message.success(`Vous avez sélectionné la photo : ${file.name}`);
    };

    const handleFileRemove = () => {
        message.success(`Le fichier "${selectedFile.name}" a été supprimé avec succès.`);
        setSelectedFile(null);
    };


    const handleWatchIconClick = () => {
        setIsModalVisible(true);
    };

    const handleModalClose = () => {
        setIsModalVisible(false);
    };

    const handleSave = () => {
        const telephone = document.getElementById('telephone').value;
        const reader = new FileReader();
        // Vérifie si selectedFile est vide ou null (A revoir)
        axiosInstance.put(`https://api.mytic-ci.com/Utilisateurs`, {
            telephone: telephone,
        })
            .then(response => {
                // Handle response if needed
                console.log('Data saved successfully:', response.data);
                message.success('Modification réussie');
            })
            .catch(error => {
                console.error('Error saving data:', error);
                message.error('Modification échouée');
            });
    };

    // Function to convert Blob to hexadecimal string
    function blobToHexString(blob) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsArrayBuffer(blob);
            reader.onloadend = () => {
                const bytes = new Uint8Array(reader.result);
                const hexBytes = [];
                for (let i = 0; i < bytes.length; i++) {
                    const byteString = bytes[i].toString(16);
                    const paddedByteString = byteString.padStart(2, '0');
                    hexBytes.push(paddedByteString);
                }
                resolve(hexBytes.join(''));
            };
            reader.onerror = reject;
        });
    }
    return (
        <div>
            <div>
                <header className="header">
                    <Header />
                </header>
            </div>
            {loading ? (
                <div className='info-vehicle-loader'>
                    <TireLoader />
                </div>
            ) : (
                <div className='d-flex down-body'>
                    <div className='left-part'>
                        {selectedFile ? (
                            <div className='photo-profil'>
                                <div className='image-frame' style={{ marginRight: '20px' }}>
                                    <img src={URL.createObjectURL(selectedFile.file)} alt={selectedFile.name} className='image-frame' />
                                    <div className='file-name-overlay'>
                                        <p>{selectedFile.name}</p>
                                    </div>
                                    <div className='to-center'>
                                        <div className='on-image-icons watch-icon' onClick={handleWatchIconClick}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="16" viewBox="0 0 22 16" fill="none">
                                                <path d="M11 5C10.2044 5 9.44129 5.31607 8.87868 5.87868C8.31607 6.44129 8 7.20435 8 8C8 8.79565 8.31607 9.55871 8.87868 10.1213C9.44129 10.6839 10.2044 11 11 11C11.7956 11 12.5587 10.6839 13.1213 10.1213C13.6839 9.55871 14 8.79565 14 8C14 7.20435 13.6839 6.44129 13.1213 5.87868C12.5587 5.31607 11.7956 5 11 5ZM11 13C9.67392 13 8.40215 12.4732 7.46447 11.5355C6.52678 10.5979 6 9.32608 6 8C6 6.67392 6.52678 5.40215 7.46447 4.46447C8.40215 3.52678 9.67392 3 11 3C12.3261 3 13.5979 3.52678 14.5355 4.46447C15.4732 5.40215 16 6.67392 16 8C16 9.32608 15.4732 10.5979 14.5355 11.5355C13.5979 12.4732 12.3261 13 11 13ZM11 0.5C6 0.5 1.73 3.61 0 8C1.73 12.39 6 15.5 11 15.5C16 15.5 20.27 12.39 22 8C20.27 3.61 16 0.5 11 0.5Z" fill="white" />
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div>
                                <label htmlFor="actual-btn" className='photo-profil'>
                                    <div className='add-plus'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="125" height="125" viewBox="0 0 100 100">
                                            <line x1="10" y1="50" x2="90" y2="50" stroke="#015677" strokeWidth="10" strokeLinecap="round" />
                                            <line x1="50" y1="10" x2="50" y2="90" stroke="#015677" strokeWidth="10" strokeLinecap="round" />
                                        </svg>
                                        <div className='add-text'>Ajouter une photo</div>
                                    </div>
                                </label>
                                <input type="file" id="actual-btn" hidden onChange={handleFileChange} accept="image/*" />
                            </div>

                        )}
                        {selectedFile && (
                            <Modal
                                open={isModalVisible}
                                onCancel={handleModalClose}
                                footer={null}
                                title={selectedFile.name}
                                maskClosable={true}
                            >
                                <div className="image-background">
                                    <img
                                        src={URL.createObjectURL(selectedFile.file)}
                                        alt={selectedFile.name}
                                        style={{ width: '100%', height: '100%' }}
                                        name="photo_profil"
                                    />
                                </div>
                            </Modal>
                        )}
                    </div>
                    <div className='right-part mt-10'>
                        <div className="inputs-row">
                            <div className='info-vehicle-input-container my-2'>
                                <label htmlFor="nom">Nom</label>
                                <input id="nom" className="info-vehicle-input readonly" defaultValue={datas.nom} name="nom" readOnly />
                            </div>
                            <div className='info-vehicle-input-container my-2'>
                                <label htmlFor="prenoms">Prénoms</label>
                                <input id="prenoms" className="info-vehicle-input readonly" defaultValue={datas.prenoms} name="prenoms" readOnly />
                            </div>
                        </div>
                        <div className='inputs-row'>
                            <div className='info-vehicle-input-container my-2'>
                                <label htmlFor="telephone">Numéro de téléphone</label>
                                <input id="telephone" className="info-vehicle-input" name="telephone" defaultValue={datas.telephone} />
                            </div>
                            <div className='info-vehicle-input-container my-2'>
                                <label htmlFor="role">Rôle</label>
                                <input id="role" className="info-vehicle-input readonly" name="role" defaultValue={datas.role} readonly={true} />
                            </div>
                        </div>
                        <div className='inputs-row'>
                            <div className='info-vehicle-input-container my-2'>
                                <label htmlFor="created_at">Date d'enregistrement</label>
                                <input id="created_at" className="info-vehicle-input readonly" name="created_at" defaultValue={datas.created_at} readonly={true} />
                            </div>
                        </div>
                        <div>
                            <button className='btn-enregistrer' onClick={handleSave}>Enregistrer</button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default Utilisateur;