import React, { useState, useEffect } from 'react';
import axios from '../../axios/axiosInstance';
import Select from 'react-select';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Restauration.css';
import Haut from '../../images/intervention/haut.jpg';
import Avant from '../../images/intervention/avant.jpg';
import Arriere from '../../images/intervention/arriere.jpg';
import Gauche from '../../images/intervention/gauche.jpg';
import Droite from '../../images/intervention/droite.jpg';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';
import { useNavigate, useParams } from 'react-router-dom';
import { message } from 'antd';

function Restauration() {
    const navigate = useNavigate();
    const AxiosInstance = axios();

    const [formData, setFormData] = useState({
        immatriculation: '',
        elementsRestaures: '',
        prix: '',
    });
    const [shapes, setShapes] = useState([]);
    const [isAccidentSelected, setAccidentSelected] = useState(false);
    const [immatriculationOptions, setImmatriculationOptions] = useState([]);

    useEffect(() => {
        // Récupérer la liste des immatriculations depuis l'API 'https://api.mytic-ci.com/Vehicules'
        const fetchImmatriculations = async () => {
            try {
                const response = await AxiosInstance.get('https://api.mytic-ci.com/Vehicules');
                const immatriculations = response.data.map(vehicule => ({ value: vehicule.immatriculation, label: vehicule.immatriculation }));
                setImmatriculationOptions(immatriculations);
            } catch (error) {
                console.error('Erreur lors de la récupération des immatriculations:', error);
            }
        };

        fetchImmatriculations();
    }, [AxiosInstance]);

    const handleCanvasClick = (event) => {
        const boundingRect = event.currentTarget.getBoundingClientRect(); // Obtenir les coordonnées du conteneur
        const offsetX = event.clientX - boundingRect.left; // Coordonnée x relative à la zone de dessin
        const offsetY = event.clientY - boundingRect.top; // Coordonnée y relative à la zone de dessin

        const newShape = {
            x: offsetX - 23,
            y: offsetY - 23,
            type: 'circle',
        };

        setShapes([...shapes, newShape]);
    };

    const handleCircleClick = (index) => {
        const updatedShapes = shapes.filter((_, i) => i !== index);
        setShapes(updatedShapes);
    };

    const [isChecked, setIsChecked] = useState(false);

    const [selected, setSelected] = useState(null);
    const [selectedlenght, setSelectedlenght] = useState(null);

    const handleCheckboxChange = (event) => {
        setAccidentSelected(event.target.checked);
    };

    const handleSelectChange = (selectedOption) => {
        const immatriculationValue = document.getElementById('immatriculation').textContent;
        const isCarosserieSelected = selectedOption && selectedOption.value === 'Carosserie';
        setAccidentSelected(isCarosserieSelected);

        setFormData({
            ...formData,
            elementsRestaures: selectedOption ? selectedOption.value : [],
        });
    };

    const handleFieldChange = (fieldName) => (event) => {
        setFormData({
            ...formData,
            [fieldName]: event.target.value,
        });
    };

    const optionList = [
        { value: "Carosserie", label: "Accident" },
        { value: "Boite de vitesse", label: "Boite de vitesse" },
        { value: "Cric", label: "Cric" },
        { value: "Enjoliveurs", label: "Enjoliveurs" },
        { value: "Essuie-glace", label: "Essuie-glace" },
        { value: "Feux arrières", label: "Feux arrières" },
        { value: "Kit de sécurité", label: "Kit de sécurité" },
        { value: "Manivelle", label: "Manivelle" },
        { value: "Passage de roue", label: "Passage de roue" },
        { value: "Phares", label: "Phares" },
        { value: "pneus", label: "pneus" },
        { value: "Roue de secours", label: "Roue de secours" },
        { value: "Tapis", label: "Tapis" },
    ];

    const handleSubmit = async (event) => {
        event.preventDefault();

        // Vérifiez si "Carosserie" n'est pas sélectionné
        // Vérifiez si "Carosserie" n'est pas sélectionné
        if (!isAccidentSelected) {
            try {
                const immatriculationValue = document.getElementById('immatriculation').textContent;

                const updatedFormData = {
                    ...formData,
                    immatriculation: immatriculationValue,  
                };

                console.log(updatedFormData);

                // Envoyez les données via Axios avec la nouvelle immatriculation
                AxiosInstance.post('https://api.mytic-ci.com/Restaurations/AjouterRestauration', updatedFormData);

                // Traitez la réponse si nécessaire
                await message.success('Restauration ajoutée avec succès');
                navigate('/Interventions')
            } catch (error) {
                // Traitez la réponse si nécessaire
                await message.success('Restauration ajoutée avec succès');
                navigate('/Interventions')
            }
        }
        else {
            // Accidents est vrai, envoyez les données des cercles
            try {
                const immatriculationValue = document.getElementById('immatriculation').textContent;

                const dataToSend = {
                    immatriculation: immatriculationValue,
                    elementsRestaures: formData.elementsRestaures,
                    prix: formData.prix,
                    circleCoordinates: shapes,
                };

                console.log(dataToSend);

                // Envoyez les données avec les coordonnées des cercles via Axios
                const response = await AxiosInstance.post('https://api.mytic-ci.com/Restaurations/AjouterRestauration', dataToSend);
                message.success('Restauration ajoutée avec succès');
                navigate('/Interventions');
            } catch (error) {
                console.error('Erreur lors de l\'envoi des données:', error);
                message.error('Restauration échouée');
            }
        }
    };


    return (
        <div>
            <div>
                <div>
                    <div>
                        <form>
                            <div className='mt-4'>
                                <div className='info-vehicle-input-container mb-4'>
                                    <label htmlFor="immatriculation">Immatriculation</label>
                                    <div>
                                        <Select
                                            id="immatriculation"
                                            className="info-vehicle-input to-not-index"
                                            name="immatriculation"
                                            options={immatriculationOptions}
                                            onChange={(selectedOption) => console.log(selectedOption)} // Gérer la sélection de l'option
                                            styles={{

                                                input: (baseStyles, state) => ({
                                                    ...baseStyles,


                                                }),

                                                control: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    border: 'none',
                                                    borderRadius: '4px',
                                                    backgroundColor: '#EDEDED',
                                                    color: '#333',
                                                    height: '43px',
                                                    width: '200px',
                                                    outline: 'none',
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    ':focus': {
                                                        marginLeft: '20px'
                                                    }
                                                }),
                                                dropdownIndicator: () => ({
                                                    marginLeft: '10px',
                                                    marginRight: '10px',
                                                    ':focus': {
                                                        border: '1px solid'
                                                    }
                                                }),
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className='d-flex'>
                                    <div className='mx-4'>
                                        <label htmlFor="name" class="Immatriculation">Eléments restaurés</label>
                                        <Select
                                            options={optionList}
                                            placeholder="Sélectionnez"
                                            onChange={handleSelectChange}
                                            isSearchable={true}
                                            styles={{

                                                input: (baseStyles, state) => ({
                                                    ...baseStyles,


                                                }),

                                                control: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    border: 'none',
                                                    borderRadius: '4px',
                                                    backgroundColor: '#EDEDED',
                                                    color: '#333',
                                                    minHeight: '45px',
                                                    width: '310px',
                                                    outline: 'none',
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    ':focus': {
                                                        marginLeft: '20px'
                                                    }
                                                }),
                                                dropdownIndicator: () => ({
                                                    marginLeft: '10px',
                                                    marginRight: '10px',
                                                    ':focus': {
                                                        border: '1px solid'
                                                    }
                                                }),
                                            }}

                                        />
                                    </div>
                                    <div className='input-container'>
                                        <label htmlFor="name" class="name">Prix</label>
                                        <br />
                                        <input type='number' className="info-vehicle-input" onChange={handleFieldChange('prix')} name="prix" />
                                        <span class="input-with-unit-fcfa"></span>
                                        <div class="underline"></div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                {isAccidentSelected ?
                                    <div>
                                        <div className='card mx-4 mt-3' style={{ width: '750px', marginLeft: '0px' }}
                                            onClick={handleCanvasClick}>
                                            <Row style={{ marginTop: '20px', }}>
                                                <Col style={{ marginLeft: '250px' }}>
                                                    <Image src={Haut} height="110px" rounded />
                                                </Col>
                                            </Row>
                                            <Row style={{ marginTop: '50px', marginLeft: '20px' }}>
                                                <Col>
                                                    <Image src={Gauche} height="110px" rounded />
                                                </Col>
                                                <Col>
                                                    <Image src={Droite} height="110px" rounded />
                                                </Col>
                                            </Row>
                                            <Row style={{ marginTop: '50px', marginLeft: '180px', marginBottom: '20px' }}>
                                                <Col>
                                                    <Image src={Arriere} height="110px" rounded />
                                                </Col>
                                                <Col>
                                                    <Image src={Avant} height="110px" rounded />
                                                </Col>
                                            </Row>
                                            {shapes.map((shape, index) => (
                                                <div
                                                    key={index}
                                                    onClick={() => handleCircleClick(index)}
                                                    style={{
                                                        position: 'absolute',
                                                        left: shape.x,
                                                        top: shape.y,
                                                        width: '50px',
                                                        height: '50px',
                                                        borderRadius: '50%',
                                                        border: '2px solid red',
                                                        cursor: 'pointer',
                                                    }}>
                                                </div>
                                            ))}

                                        </div>
                                        <div>
                                            <button className='btn-enregistrer mx-4 mt-2' onClick={handleSubmit} style={{ marginTop: '10px' }}>Enregistrer</button>
                                        </div>
                                    </div>
                                    :
                                    <div>
                                        <button className='btn-enregistrer mx-4 mt-2' onClick={handleSubmit} style={{ marginTop: '250px' }}>Enregistrer</button>
                                    </div>
                                }
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Restauration;
