import { React, useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { message } from 'antd';
import Header from './HeaderVehicule';
import './AjoutVehicule.css';
import UnknowVehicle from '../images/vehicule/unknow-vehicle.png'
import MarquesJson from './donnees_fichiers_json/Marques.json';
import ModelesJson from './donnees_fichiers_json/Modeles.json';
import axios from '../axios/axiosInstance';


function AjoutVehicule(props) {
    const axiosInstance = axios()
    const [marques, setMarques] = useState([]);
    const [modeles, setModeles] = useState([]);
    const navigate = useNavigate();  // Assurez-vous d'importer useNavigate
    const [typeCarburant, setTypeCarburant] = useState('essence');
    useEffect(() => {
        setMarques(MarquesJson);
        setModeles(ModelesJson);
    }, []);


    const statusOptions = [
        { label: 'Actif', value: 'actif' },
        { label: 'Inactif', value: 'inactif' },
    ];

    const colors = [
        { value: 'rouge', label: 'Rouge' },
        { value: 'bleu', label: 'Bleu' },
        { value: 'blanc', label: 'Blanc' },
        { value: 'gris', label: 'Gris' },
        { value: 'orange', label: 'Orange' },
        { value: 'noir', label: 'Noir' },
        { value: 'marron', label: 'Marron' },
        { value: 'jaune', label: 'Jaune' },
    ];

    const handleTypeCarburantChange = (e) => {
        setTypeCarburant(e.target.value);
    };

    const handleEnregistrerClick = async () => {
        try {
            // Récupérer les valeurs des champs du formulaire
            const immatriculation = document.getElementsByName('immatriculation')[0].value;
            const statut = document.getElementsByName('statut')[0].value;
            const kilometrage_actuel = document.getElementsByName('kilometrage_actuel')[0].value;
            const kilometrage_limite = document.getElementsByName('kilometrage_limite')[0].value;
            const marque = document.getElementsByName('marque')[0].value;
            const modele = document.getElementsByName('modele')[0].value;
            const couleur = document.getElementsByName('couleur')[0].value;
            const recette = document.getElementsByName('recette')[0].value;
            const type_carburant = document.getElementsByName('type_carburant')[0].value;
            const date_mise_circulation = document.getElementsByName('date_mise_circulation')[0].value;
            const date_prochaine_visite = document.getElementsByName('date_prochaine_visite')[0].value;
            const date_fin_assurance = document.getElementsByName('date_fin_assurance')[0].value;
    
            // Vérifier si tous les champs obligatoires sont remplis
            if (
                immatriculation.trim() === '' ||
                statut.trim() === '' ||
                kilometrage_actuel.trim() === '' ||
                kilometrage_limite.trim() === '' ||
                marque.trim() === '' ||
                modele.trim() === '' ||
                couleur.trim() === '' ||
                recette.trim() === '' ||
                type_carburant.trim() === '' ||
                date_mise_circulation.trim() === '' ||
                date_prochaine_visite.trim() === '' ||
                date_fin_assurance.trim() === ''
            ) {
                message.error('Tous les champs doivent être remplis');
                return;
            }
    
            // Construire l'objet de données à envoyer
            const data = {
                immatriculation,
                statut,
                kilometrage_actuel,
                kilometrage_limite,
                marque,
                modele,
                couleur,
                recette,
                type_carburant,
                date_mise_circulation,
                date_prochaine_visite, // Ajouter la date de prochaine visite
                date_fin_assurance,    // Ajouter la date de fin d'assurance
            };
    
            console.log(data);
    
            // Envoyer la requête POST à l'API
            const response = await axiosInstance.post('https://api.mytic-ci.com/Vehicules/AjoutVehicule', data);
    
            // Traiter la réponse si nécessaire
            console.log(response.data);
    
            message.success('Véhicule enregistré avec succès');
            navigate('/AjoutIntervention');
        } catch (error) {
            // Gérer les erreurs de requête
            console.error('Erreur lors de l\'enregistrement des données:', error.message);
        }
    };
    



    return (
        <div>
            <header>
                <Header />
            </header>
            <body>
                <div className='d-flex down-body'>
                    <div className='left-part'>
                        <div className='vehicule-image mt-4'>
                            <img src={UnknowVehicle} style={{ height: '45%', width: '45%', marginLeft: '20%' }} alt="Vehicle" />
                        </div>
                        <div>
                            <input className='input-immatriculation-vehicule mt-4' placeholder='XX-XXXX-XX' name='immatriculation' style={{ backgroundColor: '#d0d0d0' }} required />
                        </div>
                    </div>
                    <div className='right-part mt-5'>
                        <div>
                            <div className='inputs-row'>
                                <div className='info-vehicle-input-container my-2'>
                                    <label htmlFor="statut">Statut</label>
                                    <select id="statut" className="info-vehicle-input custom-select" name="statut">
                                        {statusOptions.map((filteredOption) => (
                                            <option key={filteredOption.value} value={filteredOption.value}>
                                                {filteredOption.label}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className='inputs-row'>
                                <div className='info-vehicle-input-container my-2'>
                                    <label htmlFor="kilometrage">Kilométrage actuel</label>
                                    <div className="input-with-unit">
                                        <input id="kilometrage" className="info-vehicle-input" name="kilometrage_actuel" />
                                    </div>
                                </div>
                                <div className='info-vehicle-input-container my-2'>
                                    <label htmlFor="kilometrage_limite">Kilométrage limite</label>
                                    <div className="input-with-unit">
                                        <input id="kilometrage_limite" className="info-vehicle-input" name="kilometrage_limite" />
                                    </div>
                                </div>
                            </div>
                            <div className="inputs-row">
                                <div className='info-vehicle-input-container my-2'>
                                    <label htmlFor="marque">Marque</label>
                                    <select id='marque' className='info-vehicle-input custom-select' name='marque' required>
                                        {marques.map((marque) => (
                                            <option key={marque.value} value={marque.value}>
                                                {marque.label}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className='info-vehicle-input-container my-2'>
                                    <label htmlFor='modele'>Modèle</label>
                                    <select id='modele' className='info-vehicle-input custom-select' name='modele' required>
                                        {modeles.map((modele) => (
                                            <option key={modele.value} value={modele.value}>
                                                {modele.label}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className="inputs-row">
                                <div className='info-vehicle-input-container my-2'>
                                    <label htmlFor="couleur">Couleur</label>
                                    <select
                                        id="couleur"
                                        className="info-vehicle-input custom-select"
                                        name="couleur"
                                        required
                                    >
                                        {colors.map((color) => (
                                            <option key={color.value} value={color.value}>
                                                {color.label}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className='info-vehicle-input-container my-2'>
                                    <label htmlFor="recette">Recette</label>
                                    <div className="input-with-unit-fcfa">
                                        <input id="recette" className="info-vehicle-input" name="recette" />
                                    </div>
                                </div>
                            </div>
                            <div className="inputs-row">
                                <div className='info-vehicle-input-container my-2'>
                                    <label htmlFor="type_carburant">Type de carburant</label>
                                    <select
                                        id="type_carburant"
                                        className="info-vehicle-input custom-select"
                                        name="type_carburant"
                                        onChange={handleTypeCarburantChange}
                                        value={typeCarburant}
                                        required
                                    >
                                        <option value="essence">Essence</option>
                                        <option value="gazoil">Gazoil</option>
                                    </select>                                </div>
                                <div className='info-vehicle-input-container my-2'>
                                    <label htmlFor="date_mise_circulation">Mise en circulation</label>
                                    <input id="date_mise_circulation" type='date' className="info-vehicle-input to-date" name="date_mise_circulation" required />
                                </div>
                            </div>
                            <div className="inputs-row">
                                <div className='info-vehicle-input-container my-2'>
                                    <label htmlFor="date_prochaine_visite">Prochaine visite</label>
                                    <input
                                        id="date_prochaine_visite"
                                        className="info-vehicle-input"
                                        name="date_prochaine_visite"
                                        type="date"
                                    />
                                </div>
                                <div className='info-vehicle-input-container my-2'>
                                    <label htmlFor="date_fin_assurance">Date de fin d'assurance</label>
                                    <input
                                        id="date_fin_assurance"
                                        className="info-vehicle-input"
                                        name="date_fin_assurance"
                                        type="date"
                                    />
                                </div>
                            </div>
                        </div>
                        <div>
                            <button className="btn-enregistrer" style={{ width: '420px' }} onClick={handleEnregistrerClick}>Enregistrer</button>
                        </div>
                    </div>
                </div>
            </body>
        </div>
    );
}

export default AjoutVehicule;