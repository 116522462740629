import React, { useEffect, useState } from 'react';
import './InfoVehicule.css';
import axios from '../axios/axiosInstance';
import Header from './HeaderVehicule';
import moment from 'moment';
import altoorange from '../images/vehicule/suzuki/alto/orange/orange.png';
import altomarron from '../images/vehicule/suzuki/alto/marron/marron.png';
import spressogris from '../images/vehicule/suzuki/spresso/gris/gris.png';
import spressorouge from '../images/vehicule/suzuki/spresso/rouge/rouge.png';
import spressoblanc from '../images/vehicule/suzuki/spresso/blanc/blanc.png';
import swiftgris from '../images/vehicule/suzuki/swift/gris/gris.png';
import swiftrouge from '../images/vehicule/suzuki/swift/rouge/rouge.png';
import dzireblanc from '../images/vehicule/suzuki/dzire/blanc/blanc.png';
import dziregris from '../images/vehicule/suzuki/dzire/gris/gris.png';
import dzirerouge from '../images/vehicule/suzuki/dzire/rouge/rouge.png';
import dzirebleu from '../images/vehicule/suzuki/dzire/bleu/bleu.png';
import TireLoader from "../loaders/TireLoader"
import { useNavigate, useParams } from 'react-router-dom';
import { message, Modal, Button } from 'antd';

function getImagePath(vehicleData) {
    switch (`${vehicleData.modele}${vehicleData.couleur}`) {
        case 'altoorange':
            return altoorange;
        case 'altomarron':
            return altomarron;
        case 'spressogris':
            return spressogris;
        case 'spressorouge':
            return spressorouge;
        case 'spressoblanc':
            return spressoblanc;
        case 'swiftgris':
            return swiftgris;
        case 'swiftrouge':
            return swiftrouge;
        case 'dziregris':
            return dziregris;
        case 'dzirerouge':
            return dzirerouge;
        case 'dzirebleu':
            return dzirebleu;
        case 'dzireblanc':
            return dzireblanc;
        default:
            return ''; // Retourne une chaîne vide si aucun nom correspondant n'est trouvé
    }
}

function formatDate(dateString) {
    const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
    const formattedDate = new Date(dateString).toLocaleDateString('fr-FR', options);
    return formattedDate;
}


function formatRecette(recette) {
    // Vérifie si la recette est définie
    if (recette === undefined || recette === null) {
        return ''; // Retourne une chaîne vide si la recette n'est pas définie
    }

    // Convertit la recette en chaîne
    const recetteStr = recette.toString();

    // Ajoute des espaces chaque trois chiffres en partant de la droite
    const formattedRecette = recetteStr.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

    return formattedRecette;
}

function capitalizeFirstLetter(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
}





function InfoVehicule(props) {
    const axiosInstance = axios()
    const [isModalVisible, setIsModalVisible] = useState(false);  // État pour gérer la visibilité de la fenêtre modale
    const [vehicleData, setVehicleData] = useState({});
    const [loading, setLoading] = useState(true);
    const [nextControlDate, setNextControlDate] = useState('');  // Ajoutez l'état pour stocker la date du prochain contrôle
    const navigate = useNavigate();  // Assurez-vous d'importer useNavigate
    const { id_vehicule } = useParams();
    const [oldStatut, setOldStatut] = useState(''); // Nouvelle variable pour stocker l'ancien statut
    const [latestActivityDate, setLatestActivityDate] = useState('');
    const [latestActivityHour, setLatestActivityHour] = useState('');
    const [inputModalVisible, setInputModalVisible] = useState(false);
    const [inputModalValue, setInputModalValue] = useState('');

    const statusOptions = [
        { label: 'Actif', value: 'actif' },
        { label: 'Inactif', value: 'inactif' },
    ];

    const colors = [
        { value: 'rouge', label: 'Rouge' },
        { value: 'bleu', label: 'Bleu' },
        { value: 'blanc', label: 'Blanc' },
        { value: 'gris', label: 'Gris' },
        { value: 'orange', label: 'Orange' },
        { value: 'noir', label: 'Noir' },
        { value: 'marron', label: 'Marron' },
        { value: 'jaune', label: 'Jaune' },
    ];

    useEffect(() => {
        const fetchData = async () => {
            try {
                // Récupérer les données du véhicule
                const vehicleResponse = await axiosInstance.get(`https://api.mytic-ci.com/Vehicules/${id_vehicule}`);
                const vehicleData = vehicleResponse.data[0];

                if (vehicleData) {
                    setVehicleData(vehicleData);
                    setOldStatut(vehicleData.statut);

                    // Récupérer la date de début de l'activité la plus récente
                    const activityResponse = await axiosInstance.get(`https://api.mytic-ci.com/Activites/${vehicleData.immatriculation}`);
                    const latestActivityDate = activityResponse.data[0]?.date_debut_activite || '';

                    setLatestActivityDate(moment(latestActivityDate).format('DD/MM/YYYY'));
                    setLatestActivityHour(moment(latestActivityDate).format('HH:mm:ss'));
                }

                // Récupérer la date du prochain contrôle
                const response = await axiosInstance.get(`https://api.mytic-ci.com/Controles/tocontrol/${vehicleData.immatriculation}`);
                const controlData = response.data
                const lastControlDate = moment(controlData.derniere_date_controle, 'DD/MM/YYYY');
                const nextControlDate = lastControlDate.add(7, 'days').format('DD/MM/YYYY');
                setNextControlDate(nextControlDate);
                setLoading(false);
            } catch (error) {
                console.error('Erreur lors de la récupération des données de l\'API : ', error);
                setLoading(false);
            }
        };

        fetchData();
    }, [id_vehicule]);

    const handleStatutChange = (newStatut) => {
        // Mettez à jour l'état du véhicule ou effectuez toute autre logique en fonction du changement de statut
        setVehicleData((prevData) => ({
            ...prevData,
            statut: newStatut,
        }));

        // Afficher le modal d'input si le statut est "inactif"
        if (newStatut === 'inactif') {
            setInputModalVisible(true);
        } else {
            setInputModalVisible(false);
        }
    };

    const handleInputModalOk = () => {
        // Validez l'input et mettez à jour le statut si nécessaire
        if (inputModalValue.trim() === '') {
            // L'input est vide, restaurez le statut à "actif"
            setVehicleData((prevData) => ({
                ...prevData,
                statut: 'actif',
            }));
        }

        // Fermez le modal d'input
        setInputModalVisible(false);
    };

    const handleInputModalCancel = () => {
        // Annulez le changement de statut et fermez le modal d'input
        setVehicleData((prevData) => ({
            ...prevData,
            statut: 'actif',
        }));

        setInputModalVisible(false);
    };

    const handleColorChange = (newColor) => {
        // Mettez à jour l'état du véhicule ou effectuez toute autre logique en fonction du changement de couleur
        setVehicleData((prevData) => ({
            ...prevData,
            couleur: newColor,
        }));
    };

    const handleDelete = () => {
        setIsModalVisible(true);  // Ouvrez la fenêtre modale de confirmation
    };

    const handleSubmit = async () => {
        try {
            if (!vehicleData) {
                console.error('Les données du véhicule sont indéfinies.');
                return;
            }

            // Assurez-vous que les noms d'attributs correspondent à ceux côté serveur
            const updatedVehicle = {
                statut: vehicleData.statut,
                immatriculation: vehicleData.immatriculation,
                marque: vehicleData.marque,
                modele: vehicleData.modele,
                couleur: vehicleData.couleur,
                recette: document.getElementsByName("recette")[0].value.replace(/\s/g, ''), // Supprimez les espaces lors de l'envoi
                type_carburant: vehicleData.type_carburant,
                date_mise_circulation: vehicleData.date_mise_circulation,
                kilometrage_actuel: document.getElementsByName("kilometrage_actuel")[0].value.replace(/\s/g, ''), // Supprimez les espaces lors de l'envoi
                kilometrage_limite: document.getElementsByName("kilometrage_limite")[0].value.replace(/\s/g, ''), // Supprimez les espaces lors de l'envoi
                date_prochaine_visite: document.getElementsByName("date_prochaine_visite")[0].value,
                date_fin_assurance: document.getElementsByName("date_fin_assurance")[0].value,
            };

            // Comparer la nouvelle valeur de statut avec l'ancienne
            if (updatedVehicle.statut !== oldStatut) {

                if (updatedVehicle.statut === 'actif') {
                    // Créer une nouvelle activité
                    const newActivity = {
                        statut: updatedVehicle.statut,
                        immatriculation: updatedVehicle.immatriculation,
                        date_debut_activite: moment().format('DD-MM-YYYY HH:mm:ss'),
                        date_fin_activite: null, // La nouvelle activité a une date_fin_activite initialement à null
                    };

                    console.log(newActivity);
                    // Mettre à jour la date_fin_activite de la dernière activité avec la date et l'heure actuelles
                    const lastActivity = {
                        date_fin_activite: moment().format('DD-MM-YYYY HH:mm:ss')
                    };
                    console.log(lastActivity)
                    // Envoyer la mise à jour à l'API
                    await axiosInstance.put(`https://api.mytic-ci.com/Activites/${vehicleData.immatriculation}`, lastActivity);
                    // Envoyer la nouvelle activité à l'API pour enregistrement
                    await axiosInstance.post(`https://api.mytic-ci.com/Activites/NewActivity`, newActivity);

                } else {
                    const motif = document.getElementById('motif').value;
                    // Créer une nouvelle activité
                    const newActivity = {
                        statut: updatedVehicle.statut,
                        immatriculation: updatedVehicle.immatriculation,
                        date_debut_activite: moment().format('DD-MM-YYYY HH:mm:ss'),
                        date_fin_activite: null, // La nouvelle activité a une date_fin_activite initialement à null
                        motif: motif,
                    };
                    console.log(newActivity);
                    // Mettre à jour la date_fin_activite de la dernière activité avec la date et l'heure actuelles
                    const lastActivity = {
                        date_fin_activite: moment().format('DD-MM-YYYY HH:mm:ss')
                    };
                    console.log(lastActivity)
                    // Envoyer la mise à jour à l'API
                    await axiosInstance.put(`https://api.mytic-ci.com/Activites/${vehicleData.immatriculation}`, lastActivity);
                    // Envoyer la nouvelle activité à l'API pour enregistrement
                    await axiosInstance.post(`https://api.mytic-ci.com/Activites/NewActivity`, newActivity);
                }
            }

            // Mettre à jour les données du véhicule
            await axiosInstance.put(`https://api.mytic-ci.com/Vehicules/${id_vehicule}`, updatedVehicle);

            // Afficher le message de succès
            message.success('Modification réussie');

            // Attendre 1 seconde (1000 millisecondes) avant de recharger la page
            setTimeout(() => {
                // Actualiser la page après avoir effectué toutes les opérations
                window.location.reload();
            }, 1000);

        } catch (error) {
            console.error('Erreur lors de la mise à jour des données : ', error);
            message.error('Modification échouée');

            // Gérer les erreurs...
        }
    };


    const handleConfirmDelete = async () => {
        try {
            // Assurez-vous que l'id du véhicule est défini
            if (!id_vehicule) {
                console.error("L'id du véhicule n'est pas défini.");
                return;
            }

            // Envoyez une requête de suppression à votre API
            const response = await axiosInstance.delete(`https://api.mytic-ci.com/Vehicules/${id_vehicule}`);
            console.log(response.data);

            message.success('Suppression réussie')
            navigate('/Vehicules');
        } catch (error) {
            console.error('Erreur lors de la suppression des données : ', error);
            // Gérez les erreurs ici, par exemple, affichez un message à l'utilisateur
        } finally {
            setIsModalVisible(false);  // Fermez la fenêtre modale après la suppression
        }
    };

    const handleCancelDelete = () => {
        setIsModalVisible(false);  // Annulez la suppression en fermant la fenêtre modale
    };

    return (
        <div>
            <header>
                <Header />
            </header>
            <div className='to-right'>
                {loading ? (
                    <div className='info-vehicle-loader'>
                        <TireLoader />
                    </div>
                ) : (
                    <form>
                        <div className='d-flex down-body'>
                            <div className='left-part'>
                                <div className='vehicule-image'>
                                    <img src={getImagePath(vehicleData)} style={{ height: '75%', width: '75%' }} alt="Vehicle" />
                                </div>
                                <div>
                                    <input className='input-immatriculation-vehicule' placeholder={vehicleData.immatriculation} />
                                </div>
                            </div>
                            <div className='right-part mt-1'>
                                <div>
                                    <div className='inputs-row'>
                                        <div className='info-vehicle-input-container my-2'>
                                            <label htmlFor="statut">Statut</label>
                                            <select id="statut" className="info-vehicle-input custom-select" value={vehicleData.statut} onChange={(e) => handleStatutChange(e.target.value)} name="statut">
                                                {statusOptions.map((option) => (
                                                    <option key={option.value} value={option.value}>
                                                        {option.label}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className='info-vehicle-input-container my-2'>
                                            <label htmlFor="prochain_controle">Prochain contrôle</label>
                                            <input id="prochain_controle" className="info-vehicle-input readonly" value={nextControlDate} readOnly />
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="inputs-row">
                                        <div className='info-vehicle-input-container my-2'>
                                            <label htmlFor="marque">Marque</label>
                                            <input id="marque" className="info-vehicle-input readonly" defaultValue={capitalizeFirstLetter(vehicleData.marque)} name="marque" readOnly />
                                        </div>
                                        <div className='info-vehicle-input-container my-2'>
                                            <label htmlFor="modele">Modèle</label>
                                            <input id="modele" className="info-vehicle-input readonly" defaultValue={capitalizeFirstLetter(vehicleData.modele)} name="modele" readOnly />
                                        </div>
                                    </div>
                                    <div className='inputs-row'>
                                        <div className='info-vehicle-input-container my-2'>
                                            <label htmlFor="kilometrage">Kilométrage actuel</label>
                                            <input id="kilometrage" className="info-vehicle-input readonly" defaultValue={formatRecette(vehicleData.kilometrage_actuel)} name="kilometrage_actuel" readonly={true} />
                                        </div>
                                        <div className='info-vehicle-input-container my-2'>
                                            <label htmlFor="kilometrage_limite">Kilométrage limite</label>
                                            <input id="kilometrage_limite" className="info-vehicle-input readonly" defaultValue={formatRecette(vehicleData.kilometrage_limite)} name="kilometrage_limite" readonly={true} />
                                        </div>
                                    </div>
                                    <div className="inputs-row">
                                        <div className='info-vehicle-input-container my-2'>
                                            <label htmlFor="couleur">Couleur</label>
                                            <select
                                                id="couleur"
                                                className="info-vehicle-input custom-select"
                                                value={vehicleData.couleur}
                                                onChange={(e) => handleColorChange(e.target.value)}
                                                name="couleur"
                                            >
                                                {colors.map((color) => (
                                                    <option key={color.value} value={color.value}>
                                                        {color.label}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className='info-vehicle-input-container my-2'>
                                            <label htmlFor="recette">Recette</label>
                                            <input id="recette" className="info-vehicle-input" defaultValue={formatRecette(vehicleData.recette)} name="recette" />
                                        </div>
                                    </div>
                                    <div className="inputs-row">
                                        <div className='info-vehicle-input-container my-2'>
                                            <label htmlFor="type_carburant">Type de carburant</label>
                                            <input id="type_carburant" className="info-vehicle-input readonly" defaultValue={capitalizeFirstLetter(vehicleData.type_carburant)} name="type_carburant" readOnly />
                                        </div>
                                        <div className='info-vehicle-input-container my-2'>
                                            <label htmlFor="date_mise_circulation">Mise en circulation</label>
                                            <input id="date_mise_circulation" className="info-vehicle-input readonly" defaultValue={formatDate(vehicleData.date_mise_circulation)} name="date_mise_circulation" readOnly />
                                        </div>
                                    </div>
                                    <div className="inputs-row">
                                        <div className='info-vehicle-input-container my-2'>
                                            <label htmlFor="date_prochaine_visite">Prochaine visite</label>
                                            <input
                                                id="date_prochaine_visite"
                                                className="info-vehicle-input"
                                                defaultValue={capitalizeFirstLetter(vehicleData.date_prochaine_visite)}
                                                name="date_prochaine_visite"
                                                type="date"
                                            />
                                        </div>
                                        <div className='info-vehicle-input-container my-2'>
                                            <label htmlFor="date_fin_assurance">Date de fin d'assurance</label>
                                            <input
                                                id="date_fin_assurance"
                                                className="info-vehicle-input"
                                                defaultValue={vehicleData.date_fin_assurance}
                                                name="date_fin_assurance"
                                                type="date"
                                            />
                                        </div>
                                    </div>

                                    <div className="inputs-row">
                                        <div className='info-vehicle-input-container my-2'>
                                            <label htmlFor="date_enregistrement">Date d'enregistrement</label>
                                            <input type="date" id="date_enregistrement" className="info-vehicle-input readonly" defaultValue={vehicleData.date_enregistrement} name="date_enregistrement" readOnly />
                                        </div>
                                    </div>
                                </div>
                                <div className='d-flex'>
                                    <div>
                                        <input type='button' onClick={handleSubmit} className="btn-enregistrer" value='Enregistrer' />
                                    </div>
                                </div>
                            </div>
                        </div>

                    </form>

                )}
                {/* Fenêtre modale de confirmation de suppression */}
                <Modal
                    title="Confirmation de suppression"
                    visible={isModalVisible}
                    onOk={handleConfirmDelete}
                    onCancel={handleCancelDelete}
                    okText="Supprimer"
                    cancelText="Annuler"
                    style={{ marginTop: '10%' }}
                >
                    <p>Êtes-vous sûr de vouloir supprimer ce véhicule?</p>
                </Modal>
                <Modal
                    title="Motif"
                    visible={inputModalVisible}
                    onOk={handleInputModalOk}
                    onCancel={handleInputModalCancel}
                    okText="Valider"
                    cancelText="Annuler"

                    style={{ marginTop: '10%' }}
                >
                    <p>Pourquoi ce véhicule est inactif ?</p>
                    <input
                        id='motif'
                        name='motif'
                        type="text"
                        value={inputModalValue}
                        className='info-vehicle-input'
                        onChange={(e) => setInputModalValue(e.target.value)}
                    />
                </Modal>
            </div>
        </div>
    );
}

export default InfoVehicule;
