import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './login.css';
import loginImage from '../images/login/login.gif';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

const Login = () => {
    // Supprimer le token du localStorage lors du chargement initial
    localStorage.removeItem('mytic_token');
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        username: '',
        password: '',
    });
    const [alert, setAlert] = useState(null);


    useEffect(() => {
        // Vérifier si un token est déjà présent dans le localStorage
        const storedToken = localStorage.getItem('mytic_token');
        if (storedToken) {
            navigate('/Vehicules');
        }
    }, [navigate]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleLogin = async () => {
        try {
            setAlert(
                <Alert severity="info">
                    <AlertTitle>En cours d'authentification</AlertTitle>
                    Authentiofication en cours...
                </Alert>
            );
            // Effectuer la requête POST pour l'authentification
            const response = await axios.post('https://api.mytic-ci.com/Vehicules/Login', formData);

            // Extraire le token de la réponse
            const { mytic_token } = response.data;

            // Stocker le token dans le localStorage
            localStorage.setItem('mytic_token', mytic_token);

            // Afficher l'Alerte de succès
            await setAlert(
                <Alert severity="success">
                    <AlertTitle>Authentification réussie</AlertTitle>
                    En cours de redirection...
                </Alert>
            );

    navigate('/Vehicules');

        } catch (error) {
            console.error('Error fetching data:', error);

            // Afficher l'Alerte d'échec
            setAlert(
                <Alert severity="error">
                    <AlertTitle>Authentification échouée</AlertTitle>
                    Nom d'utilisateur ou mot de passe incorrect
                </Alert>
            );
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        handleLogin();
    };

    return (
        <body className='form-body'>
            <div className="custom-alert-container">
                {alert && (
                    <div className="custom-alert">
                        {alert}
                    </div>
                )}
            </div>
            <div className='form-support'>
                <div className='design-space'>
                    <img src={loginImage} className='form-image mt-4' width='110%' />
                </div>
                <div className='form-space mx-2'>
                    <h4 className='welcome-back'>Content de te revoir !</h4>
                    <form onSubmit={handleSubmit}>
                        <div>
                            <input
                                type="text"
                                name="username"
                                className='form-input'
                                value={formData.username}
                                onChange={handleInputChange}
                                placeholder='Username'
                            />
                        </div>
                        <div class="mt-4">
                            <input
                                type="password"
                                name="password"
                                className='form-input'
                                value={formData.password}
                                onChange={handleInputChange}
                                placeholder='Password'
                            />
                        </div>
                        <button type="submit" className='btn-connecter mt-3'>Se connecter</button>
                    </form>
                </div>
            </div>
        </body>
    );
};

export default Login;
