import { React, useEffect, useState, useCallback } from 'react';
import "bootstrap/dist/css/bootstrap.min.css"
import { message } from 'antd';
import { BrowserRouter as Router, Route, Link, useNavigate } from 'react-router-dom';
import Logo from '../images/logo.png';
import axios from '../axios/axiosInstance';

function HeaderVehicule(props) {
    const navigate = useNavigate();
    const [userData, setUserData] = useState({ nom: '', prenoms: '' });
    const [selectedFile, setSelectedFile] = useState(null)
    const axiosInstance = axios()
    const [loading, setLoading] = useState(true);

    // Utilisez useCallback pour encapsuler la fonction fetchUserData
    const fetchUserData = useCallback(async () => {
        try {
            const response = await axiosInstance.get('https://api.mytic-ci.com/Utilisateurs');
            const userDataFromApi = response.data;
            setUserData(userDataFromApi[0]);
            setLoading(false);

            if (response.data[0].photo_profil.data.length !== 0) {
                const imageDataArray = response.data[0].photo_profil.data;
                const byteArray = new Uint8Array(imageDataArray);
                const blob = new Blob([byteArray], { type: 'image/jpeg' });
                setSelectedFile({ file: blob, name: userDataFromApi[0].image_name });
            }
        } catch (error) {
            console.error('Une erreur s\'est produite lors de la récupération des données :', error);
        }
    }, [axiosInstance]); // Incluez axiosInstance dans le tableau des dépendances

    useEffect(() => {
        fetchUserData();
    }, [fetchUserData]); // Incluez fetchUserData dans le tableau des dépendances

    useEffect(() => {
        // Vous pouvez également inclure userData.image_name ici si nécessaire
        if (userData.image_name) {
            // Faites quelque chose avec userData.image_name si nécessaire
        }
    }, [userData.image_name]);

    async function Logout() {
        await message.success('Déconnexion en cours...');
        localStorage.removeItem('mytic_token');
        navigate('/');
    };

    return (
        <div className='container no-margin'>
            <div className='row'>
                <div className='navbar-body d-none d-sm-block'>
                    <div className='row'>
                        <img src={Logo} className='logo mt-4' />
                    </div>
                    {loading ?
                        <div>
                        </div>
                        :
                        <div className='col-mx-6 items'>
                            <div className='user-div mb-4'>
                                <div className='user-image'>
                                    {selectedFile ?
                                        <img src={URL.createObjectURL(selectedFile.file)} height="100" width="100" alt={selectedFile.name} className='image-frame' />
                                        :
                                        <svg class="feather feather-user" fill="none" height="24" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" /><circle cx="12" cy="7" r="4" />
                                        </svg>
                                    }
                                </div>
                                <div className='user-name to-bold mx-3'>
                                    {userData.nom} {userData.prenoms}
                                </div>
                            </div>
                            <Link to="/Vehicules" className='des-underline'>
                                <div className='col-mx-6 other-items mt-1 to-flex'>
                                    <div className='to-top'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24">
                                            <g fill="none" fill-rule="evenodd">
                                                <path d="M24 0v24H0V0zM12.594 23.258l-.012.002l-.071.035l-.02.004l-.014-.004l-.071-.036c-.01-.003-.019 0-.024.006l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427c-.002-.01-.009-.017-.016-.018m.264-.113l-.014.002l-.184.093l-.01.01l-.003.011l.018.43l.005.012l.008.008l.201.092c.012.004.023 0 .029-.008l.004-.014l-.034-.614c-.003-.012-.01-.02-.02-.022m-.715.002a.023.023 0 0 0-.027.006l-.006.014l-.034.614c0 .012.007.02.017.024l.015-.002l.201-.093l.01-.008l.003-.011l.018-.43l-.003-.012l-.01-.01z" />
                                                <path fill="white" d="M15.764 4a3 3 0 0 1 2.683 1.658l1.386 2.771c.244-.1.484-.208.72-.324a1 1 0 0 1 .894 1.79a31.77 31.77 0 0 1-.725.312l.961 1.923A3 3 0 0 1 22 13.473V16a2.99 2.99 0 0 1-1 2.236V19.5a1.5 1.5 0 0 1-3 0V19H6v.5a1.5 1.5 0 0 1-3 0v-1.264c-.614-.55-1-1.348-1-2.236v-2.528a3 3 0 0 1 .317-1.341l.953-1.908c-.241-.102-.48-.21-.715-.327a1.01 1.01 0 0 1-.45-1.343a1 1 0 0 1 1.342-.448c.236.117.477.224.72.324l1.386-2.77A3 3 0 0 1 8.236 4zM7.5 13a1.5 1.5 0 1 0 0 3a1.5 1.5 0 0 0 0-3m9 0a1.5 1.5 0 1 0 0 3a1.5 1.5 0 0 0 0-3m-.736-7H8.236a1 1 0 0 0-.832.445l-.062.108l-1.27 2.538C7.62 9.555 9.706 10 12 10c2.142 0 4.101-.388 5.61-.817l.317-.092l-1.269-2.538a1 1 0 0 0-.77-.545L15.765 6Z" />
                                            </g>
                                        </svg>
                                    </div>
                                    <div className='to-bold item-text other-items-font'>
                                        Véhicules
                                    </div>
                                </div>
                            </Link>
                            <Link to="/Interventions" className='des-underline'>
                                <div className='col-mx-6 other-items mt-1 to-flex'>
                                    <div className='to-top'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 32 32">
                                            <path fill="white" d="M27.781 5.738c.293.293.292.801-.003 1.223l-4.504 6.449c-.4.575-1.162.741-1.562.342l-.939-.94l-1.078 1.078l-1.565-1.564l1.078-1.078l-.939-.94c-.4-.4-.234-1.161.341-1.562l6.45-4.504c.421-.294.93-.295 1.223-.002zm-16.237 11.61l3.13 3.13l-7.2 7.2a1.11 1.11 0 0 1-1.566 0l-1.564-1.566a1.109 1.109 0 0 1 0-1.565zm16.112 7.33a1.109 1.109 0 0 1 0 1.564l-1.413 1.414a1.108 1.108 0 0 1-1.566 0L11.181 14.16c-1.607.633-3.491.441-4.926-.574a5.26 5.26 0 0 1-2.15-5.368c.082-.402.593-.543.883-.252l2.039 2.04c.41.41 1.08.41 1.489 0l1.49-1.49c.41-.409.41-1.079 0-1.489L7.97 4.99c-.294-.294-.145-.807.262-.888a5.25 5.25 0 0 1 4.751 1.435a5.26 5.26 0 0 1 1.173 5.641z" />
                                        </svg>
                                    </div>
                                    <div className='to-bold item-text other-items-font'>
                                        Interventions
                                    </div>
                                </div>
                            </Link>
                            {userData.role === 'administrateur' && (
                                <Link className='des-underline'>
                                    <div className='col-mx-6 other-items mt-1 to-flex'>
                                        <div>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 17 17" fill="none">
                                                <path d="M8.5 14.875C6.87083 14.875 5.4511 14.3348 4.24079 13.2543C3.03049 12.1739 2.33703 10.8252 2.16042 9.20833H3.6125C3.77778 10.4361 4.3239 11.4514 5.25088 12.2542C6.17785 13.0569 7.26089 13.4583 8.5 13.4583C9.88125 13.4583 11.0531 12.9771 12.0155 12.0148C12.9778 11.0524 13.4588 9.88078 13.4583 8.5C13.4583 7.11875 12.9771 5.94693 12.0148 4.98454C11.0524 4.02215 9.88078 3.54119 8.5 3.54167C7.68542 3.54167 6.92396 3.73056 6.21562 4.10833C5.50729 4.48611 4.91111 5.00556 4.42708 5.66667H6.375V7.08333H2.125V2.83333H3.54167V4.49792C4.14375 3.74236 4.87876 3.15799 5.74671 2.74479C6.61465 2.3316 7.53242 2.125 8.5 2.125C9.38542 2.125 10.2149 2.29335 10.9884 2.63004C11.7619 2.96674 12.4348 3.42101 13.0071 3.99288C13.5795 4.56568 14.034 5.2386 14.3707 6.01163C14.7074 6.78465 14.8755 7.61411 14.875 8.5C14.875 9.38542 14.7067 10.2149 14.37 10.9884C14.0333 11.7619 13.579 12.4348 13.0071 13.0071C12.4343 13.5795 11.7614 14.034 10.9884 14.3707C10.2153 14.7074 9.38589 14.8755 8.5 14.875ZM10.4833 11.475L7.79167 8.78333V4.95833H9.20833V8.21667L11.475 10.4833L10.4833 11.475Z" fill="white" />
                                            </svg>
                                        </div>
                                        <div className='to-bold item-text other-items-font to-down'>
                                            Historique
                                        </div>
                                    </div>
                                </Link>
                            )}
                            <Link to="/Details" className='des-underline'>
                                <div className='col-mx-6 other-items mt-1 to-flex'>
                                    <div>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24">
                                            <path fill="white" d="M2 8a1 1 0 0 1 1-1h18a1 1 0 1 1 0 2H3a1 1 0 0 1-1-1m0 4a1 1 0 0 1 1-1h18a1 1 0 1 1 0 2H3a1 1 0 0 1-1-1m1 3a1 1 0 1 0 0 2h12a1 1 0 1 0 0-2z" />
                                        </svg>
                                    </div>
                                    <div className='to-bold item-text other-items-font to-down'>
                                        Informations
                                    </div>
                                </div>
                            </Link>
                            <div className='col-mx-6 logout-item to-flex' onClick={Logout}>
                                <div>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="15" viewBox="0 0 13 15" fill="none">
                                        <path d="M1.75627 15C1.3706 15 1.04032 14.8367 0.765441 14.51C0.490561 14.1833 0.353355 13.7911 0.353822 13.3333V1.66667C0.353822 1.20833 0.491262 0.815835 0.766142 0.489168C1.04102 0.162501 1.37107 -0.000554141 1.75627 1.41483e-06H6.66484V1.66667H1.75627V13.3333H6.66484V15H1.75627ZM9.46974 11.6667L8.50556 10.4583L10.2937 8.33333H4.56117V6.66667H10.2937L8.50556 4.54167L9.46974 3.33333L12.9759 7.5L9.46974 11.6667Z" fill="white" />
                                    </svg>
                                </div>
                                <div className='to-bold item-text other-items-font to-down mt-1'>
                                    Se déconnecter
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}

export default HeaderVehicule;