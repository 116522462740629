import React, { useState } from 'react';
import './Details.css';
import Header from './HeaderDetails';
import RecetteDetails from './RecetteDetails';
import ActivityDetails from './ActivityDetails';

function Details() {
    const [selectedDetail, setSelectedDetail] = useState('recettes-details');

    const handleDetailClick = (className) => {
        setSelectedDetail(className);
    };

    return (
        <div>
            <header className="header">
                <Header />
            </header>
            <div className='right-side'>
                <div className="toggle-state mt-4">
                    <div
                        className={`recettes-details detail d-flex align-items-center justify-content-center ${selectedDetail === 'recettes-details' ? 'selected-detail' : 'other-detail'}`}
                        onClick={() => handleDetailClick('recettes-details')}
                    >
                        Recettes
                    </div>
                    <div
                        className={`activites-details detail d-flex align-items-center justify-content-center ${selectedDetail === 'activites-details' ? 'selected-detail' : 'other-detail'}`}
                        onClick={() => handleDetailClick('activites-details')}
                    >
                        Activité
                    </div>
                </div>
                <div>
                    {selectedDetail === 'recettes-details' ?
                        <div>
                            <RecetteDetails />
                        </div>
                        :
                        <div>
                            <ActivityDetails />
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}

export default Details;
