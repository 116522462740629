// Controle.js
import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { message } from 'antd';
import axios from '../../axios/axiosInstance';
import { useNavigate, useParams } from 'react-router-dom';
import Back from '../../images/intervention/back.png';
import Haut from '../../images/intervention/haut.jpg';
import Avant from '../../images/intervention/avant.jpg';
import Arriere from '../../images/intervention/arriere.jpg';
import Gauche from '../../images/intervention/gauche.jpg';
import Droite from '../../images/intervention/droite.jpg';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';
import Loader from '../../loaders/TireLoader';

function Restauration(props) {
    const [isLoading, setIsLoading] = useState(true); // Ajout de l'état isLoading
    const { id_intervention } = useParams();
    const axiosInstance = axios();
    const [controleData, setControleData] = useState({});

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axiosInstance.get(`https://api.mytic-ci.com/Restaurations/${id_intervention}`);
                const data = response.data;
                const circles = data[0].circles;
                setControleData({ ...data[0], circles });
                setIsLoading(false); // Mettre isLoading à false une fois les données chargées
            } catch (error) {
                console.error('Error fetching controle data:', error);
                setIsLoading(false); // Mettre isLoading à false en cas d'erreur
            }
        };

        fetchData();
    }, []);

    return (
        <div>
            {isLoading ? ( // Afficher le Loader si isLoading est true
                <div className='info-vehicle-loader'>
                    <Loader />
                </div>
            ) : (
                <div>
                    <div>
                        <h3 className='type-intervention mx-4' style={{ color: '#015677' }}>Type d'intervention : <span style={{ color: 'black' }}>Restauration</span></h3>
                    </div>
                    <div>
                        <div className='mt-4'>
                            <div className='info-vehicle-input-container mb-4'>
                                <label htmlFor="immatriculation">Immatriculation</label>
                                <div>
                                    <input
                                        id="immatriculation"
                                        className="info-vehicle-input"
                                        name="immatriculation"
                                        value={controleData.immatriculation}
                                        readOnly                
                                    />
                                </div>
                            </div>
                            <div className='d-flex'>
                                <div className='mx-4 info-vehicle-input-container'>
                                    <label htmlFor="name" className="Immatriculation ">Eléments restaurés</label>
                                    <input className='info-vehicle-input' value={controleData.elementsRestaures}/>
                                </div>
                                <div className='input-container'>
                                    <label htmlFor="name" className="name">Prix</label>
                                    <br />
                                    <input type="text" className="info-vehicle-input" name="prix" value={controleData.prixRestauration}/>
                                    <span class="input-with-unit-fcfa"></span>
                                    <div class="underline"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                            {
                                //En cas d'accident
                                controleData.elementsRestaures === 'carosserie' ?
                                    <div>

                                    </div>
                                    :
                                    <div className='mt-3 mx-4'>
                                        <div className='card' style={{ width: '750px', marginLeft: '0px' }}>
                                        <Row style={{ marginTop: '20px', }}>
                                                <Col style={{ marginLeft: '250px' }}>
                                                    <Image src={Haut} height="110px" rounded />
                                                </Col>
                                            </Row>
                                            <Row style={{ marginTop: '50px', marginLeft: '20px' }}>
                                                <Col>
                                                    <Image src={Gauche} height="110px" rounded />
                                                </Col>
                                                <Col>
                                                    <Image src={Droite} height="110px" rounded />
                                                </Col>
                                            </Row>
                                            <Row style={{ marginTop: '50px', marginLeft: '180px', marginBottom: '20px' }}>
                                                <Col>
                                                    <Image src={Arriere} height="110px" rounded />
                                                </Col>
                                                <Col>
                                                    <Image src={Avant} height="110px" rounded />
                                                </Col>
                                            </Row>
                                            {controleData.circles &&
                                                JSON.parse(controleData.circles).map((circle, index) => (
                                                    <div
                                                        key={index}
                                                        style={{
                                                            position: 'absolute',
                                                            left: `${circle.x}px`,
                                                            top: `${circle.y}px`,
                                                            width: '50px',
                                                            height: '50px',
                                                            borderRadius: '50%',
                                                            border: '2px solid red',
                                                            cursor: 'pointer',
                                                        }}
                                                    ></div>
                                                ))}
                                        </div>
                                    </div>
                            }
                        </div>
                </div>
            )}
        </div>
    );
}

export default Restauration;