import React, { useState, useEffect } from 'react';
import { message } from 'antd';
import './Vidange.css';
import Select from 'react-select';
import axios from '../../axios/axiosInstance';
import { useNavigate, useParams } from 'react-router-dom';

function Vidange(props) {
    const navigate = useNavigate();
    const axiosInstance = axios();
    // Utilisez le state pour suivre les valeurs des champs de formulaire
    const [formValues, setFormValues] = useState({
        immatriculation: '',
        date_vidange: '',
        kilometrage_limite: '',
        prix: '',
    });

    const [immatriculationOptions, setImmatriculationOptions] = useState([]);

    useEffect(() => {
        // Récupérer la liste des immatriculations depuis l'API 'https://api.mytic-ci.com/Vehicules'
        const fetchImmatriculations = async () => {
            try {
                const response = await axiosInstance.get('https://api.mytic-ci.com/Vehicules');
                const immatriculations = response.data.map(vehicule => ({ value: vehicule.immatriculation, label: vehicule.immatriculation }));
                setImmatriculationOptions(immatriculations);
            } catch (error) {
                console.error('Erreur lors de la récupération des immatriculations:', error);
            }
        };

        fetchImmatriculations();
    }, [axiosInstance]);

    async function handleFormSubmit(event) {
        event.preventDefault();

        // Récupérer les valeurs d'immatriculation et kilométrage_actuel
        const immatriculationValue = document.getElementById('immatriculation').textContent;
        const kilometrageLimiteValue = formValues.kilometrage_limite;

        // Vérifier si les champs obligatoires sont remplis
        if (!immatriculationValue || !kilometrageLimiteValue) {
            message.error('Remplissez tous les champs.');
            return;
        } else {
            // Créer un objet avec les données à envoyer
            const dataToSend = {
                immatriculation: immatriculationValue,
                date_vidange: formValues.date_vidange,
                kilometrage_limite: formValues.kilometrage_limite,
                prix: formValues.prix,
            };

            try {
                // Envoie des données par Axios à votre backend
                const response = await axiosInstance.post('https://api.mytic-ci.com/Vidanges/AjouterVidange', dataToSend);
                console.log(response.data);
                message.success('Vidange ajoutée avec succès');
                navigate('/Interventions');
            } catch (error) {
                console.error(error);
                message.error('Erreur lors de l\'ajout de la vidange');
            }
        }
    };



    // Fonction pour mettre à jour le state lorsqu'un champ change
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormValues({ ...formValues, [name]: value });
    };

    return (
        <div>
            <div>
                <form>
                    <div className='mt-3'>
                        <div className='d-flex'>
                            <div className='mx-4 mb-4'>
                                <label htmlFor="immatriculation">Immatriculation</label>
                                <div>
                                    <Select
                                        id="immatriculation"
                                        className="info-vehicle-input to-not-index"
                                        name="immatriculation"
                                        options={immatriculationOptions}
                                        onChange={(selectedOption) => console.log(selectedOption)} // Gérer la sélection de l'option
                                        styles={{

                                            input: (baseStyles, state) => ({
                                                ...baseStyles,


                                            }),

                                            control: (baseStyles, state) => ({
                                                ...baseStyles,
                                                border: 'none',
                                                borderRadius: '4px',
                                                backgroundColor: '#EDEDED',
                                                color: '#333',
                                                height: '43px',
                                                width: '200px',
                                                outline: 'none',
                                                display: 'flex',
                                                flexDirection: 'row',
                                                ':focus': {
                                                    marginLeft: '20px'
                                                }
                                            }),
                                            dropdownIndicator: () => ({
                                                marginLeft: '10px',
                                                marginRight: '10px',
                                                ':focus': {
                                                    border: '1px solid'
                                                }
                                            }),
                                        }}
                                    />
                                </div>
                            </div>
                            <div className='info-vehicle-input-container'>
                                <label htmlFor="date_vidange">Date de la vidange</label>
                                <input
                                    type='date'
                                    id="date_vidange"
                                    className="info-vehicle-input"
                                    name="date_vidange"
                                    value={formValues.date_vidange}
                                    onChange={handleInputChange}
                                />
                            </div>
                        </div>
                        <div className='vidange-inputs-container'>
                            <div className='info-vehicle-input-container my-2'>
                                <label htmlFor="prix">prix</label>
                                <input
                                    type='number'
                                    id="prix"
                                    className="info-vehicle-input"
                                    name="prix"
                                    value={formValues.prix}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div className='info-vehicle-input-container my-2 mx-5'>
                                <label htmlFor="kilometrage_limite">Kilométrage limite</label>
                                <div className="input-with-unit">
                                    <input
                                        id="kilometrage_limite"
                                        className="info-vehicle-input"
                                        type='number'
                                        name="kilometrage_limite"
                                        value={formValues.kilometrage_limite}
                                        onChange={handleInputChange}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <button className='btn-enregistrer' style={{ marginTop: '17%' }} onClick={handleFormSubmit}>Enregistrer</button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default Vidange;
